
.block-wrapper.column {
  flex-direction: column;
}

.dashboard-content.admin {
  .block-wrapper.column .block .value-section .value-wrapper.no-timerange {
    flex-direction: row;
    align-items: flex-end;
  }

  .balance-chart-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .balance-chart-legend {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      h5 {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0;
      }

      .label {
        font-size: 0.875rem;
        font-weight: 500;
      }

      .balance-chart-legend-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        .label-with-dot {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;

          .dot {
            width: 1rem;
            height: 1rem;
            border-radius: 0.5rem;
          }

          p {
            font-size: 0.875rem;
            font-weight: normal;
          }
        }
      }

    }
  }


  .summary-tile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .summary-tile-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;
      margin-bottom: 1rem;

      h5 {
        font-size: 18px;
        margin: 0
      }

      .sort-title {
        font-weight: normal;
      }

      div.ant-select.ant-select-single {
        height: 21px;

        .ant-select-selector {
          padding: 0 6px;
        }

        .ant-select-arrow {
          inset-inline-end: 5px;
        }
      }
    }

    .summary-tile-content {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .summary-tile-legend {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding-right: 8px;

        .summary-tile-legend-item {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .logo {
            height: 48px;
            width: 48px;
            border-radius: 8px;
            background-size: cover;
            background-position: center;
          }

          .name-value-container {
            display: flex;
            flex-direction: column;
            padding: 6px 0;
            gap: 4px;

            .name {
              font-size: 14px;
              font-weight: normal;
            }

            .value {
              display: flex;
              flex-direction: row;
              gap: 4px;
              font-size: 14px;
              font-weight: bold;

              .dot {
                width: 14px;
                height: 14px;
                border-radius: 7px;
                background-color: var(--brandBluePrimary);
              }
            }
          }
        }
      }
    }
  }
}