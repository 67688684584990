.dashboard-content {
  .block-wrapper {

    .table-block {
      background: none;
      padding: 0;
      flex-direction: column;
      gap: 2px;
    }
  }

  .table-content-wrapper {
    table {
      .client-id-cell {
        color: var(--brandBluePrimary);
      }

      .cashback-status-cell {
        font-weight: 700;
        color: var(--brandBluePrimary);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        img {
          border-radius: 20px;
          background-color: var(--brandBluePrimary);
          width: 18px;
          height: 18px;
          padding: 2px;
          margin-right: 4px;
          vertical-align: bottom;
        }
        span {
          color: var(--white);
          border-radius: 8px;
          background-color: var(--brandBluePrimary);
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          line-height: 12px;
        }
      }

      .cashback-status-cell.red {
        color: var(--red);
      }

      .cashback-status-cell.blue {
        color: var(--brandBluePrimary)
      }

      .cashback-status-cell.green {
        color: var(--brandGreen)
      }

      .cashback-status-cell.grey {
        color: var(--gray300);
      }

      thead {
        tr {
          th {
            padding-left: 2rem;
            padding-right: 0;
          }
        }
      }

      tbody {
        tr {
          td {
            padding-left: 2rem;
          }
        }
      }
    }
  }
}
