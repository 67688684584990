/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.main-wrapper {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  position: relative;
  @media (min-width: 1366px) and (min-height: 706px) {
    padding-left: $sidebar-width;
  }
  @media (max-width: 1365px), (max-height: 705px) {
    padding-top: $mobile-sidebar-height;
  }

  h2 {
    margin: 0;
  }

  .site-content {
    padding: 3.5rem 1.5rem;
    display: flex;
    width: 100%;
    gap: 1.5rem;
    @media screen and (max-width: 1060px) {
      flex-direction: column;
    }
    @media (max-width: 900px) {
      gap: 1rem;
      padding: 1rem;
    }
  }

  .merchant-point-form {
    max-width: 500px;
  }

  .card-preview {
    flex-basis: 34%;
    max-width: 34%;
  }

  .merchant-point-form,
  .card-preview {
    @media screen and (max-width: 1060px) {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .button-wrapper {
    display: flex;
    gap: 1rem;

    .button {
      display: block;
      width: 50%;
    }
  }

  .form {
    margin-top: 1rem;

    .form-section-wrapper {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .link-button {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 0.75rem;
        cursor: pointer;
        transition: 200ms;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    input {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }

    .single-line-input {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        background-color: white;
        border: solid 1px var(--gray300);
        width: 56px;
        height: 24px;
        margin-top: 0;
        text-align: center;
        padding: 8px 0;
        color: var(--brandBluePrimary);
        font-weight: 500;
        font-size: 0.8125rem;
        line-height: 0.8125rem;

        &:disabled {
          color: var(--gray200);
        }
      }
    }

    .required-mark {
      color: var(--brandBluePrimary);
    }

    label {
      font-size: 0.8125rem;
      font-weight: 500;
    }

    .file-input-inner-wrapper.category-inner-wrapper {
      width: 75px;

      &.disabled {
        color: var(--gray200);

        .category-pick {
          &::before {
            display: none;
          }

          &:hover {
            cursor: not-allowed;
            background-color: var(--gray50);
            opacity: unset;
          }
        }
      }
    }

    .input-wrapper.category-wrapper {
      flex-direction: row;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }

    .input-wrapper.opening-hours {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      button {
        max-width: 200px;
      }

      .hours-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .opening-hours-table {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 150px;

        li {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .day-value {
            width: 70px;
          }

          p {
            font-size: 0.75rem;
            text-align: left;

            &.day-name {
              font-weight: 500;
            }

            &.closed {
              color: var(--red);
            }

          }
        }
      }
    }

    .file-input-inner-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .note {
        margin: 0;
        width: 100%;
        color: var(--gray300);
        max-width: 393px;
      }

      .sub-label {
        font-size: 0.6875rem;
      }

      .category-pick {
        width: 75px;
        aspect-ratio: 1;
        position: relative;
        font-size: 15px;
        font-weight: 400;
      }

      .category-pick::before {
        position: absolute;
        content: "";
        width: 24px;
        aspect-ratio: 1;
        border-radius: 20px;
        border: 1px solid var(--gray300);
      }

      .service-tile:hover {
        opacity: 0.6;
      }

      .remove-wrapper {
        position: relative;
        width: min-content;
        max-width: 100%;

        input {
          margin-top: 0;
        }

        .remove {
          position: absolute;
          top: -0.5rem;
          right: -0.5rem;

          img {
            width: 1rem;
            height: 1rem;
          }

          &:hover {
            cursor: pointer;
            opacity: 0.6;
          }
        }
      }
    }

    .cashback-expenditure-summary {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 0.5rem;

      .cashback-expenditure {
        display: flex;
        justify-content: space-between;

        p {
          font-size: 0.8125rem;
          margin: 0;
        }
      }

      .cashback-expenditure.total-expenditure {
        padding-top: 0.5rem;
        border-top: solid 1px black;

        p:last-child {
          font-weight: 700;
        }
      }
    }

    .input-image {
      background-size: cover;
      background-position: center;
      border-radius: 12px;
      background-color: var(--gray50)
    }

    .logo-image {
      display: block;
      height: 120px;
      width: 120px;
    }

    .input-image.logo-image::before {
      content: 'Dodaj logo';
      height: 120px;
      width: 120px;
    }

    .input-image.background-image::before {
      content: 'Dodaj zdjęcie lub wybierz proponowany kolor';
      height: 166px;
      width: 393px;
      max-width: 100%;
      @media screen and (max-width: 991px) {
        max-width: 300px;
        height: auto;
        aspect-ratio: 2.4;
      }
    }

    .input-image.background-image {
      display: block;
      height: 166px;
      width: 393px;
      max-width: 100%;
      @media screen and (max-width: 991px) {
        width: 300px;
        height: auto;
        aspect-ratio: 2.4;
      }
    }

    .input-image.selected::before {
      border: 2px solid blue;
      //content: "";
    }

    .input-image.selectable {
      &:hover::before {
        opacity: 0.6;
      }

      &::before {
        content: "";
      }
    }


    .input-image::-webkit-file-upload-button {
      visibility: hidden;
    }

    .input-image::before,
    .category-pick {
      display: flex;
      border: 2px dashed var(--gray200);
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 12px;
      outline: none;
      white-space: nowrap;
      -webkit-user-select: none;
      color: var(--gray300);
      cursor: pointer;
      text-shadow: 1px 1px #fff;
      font-weight: 700;
      font-size: 0.6875rem;
      transition: 200ms;
    }

    .category-pick {
      background-color: var(--gray50);
    }

    .input-image:hover::before,
    .category-pick:hover {
      background-color: var(--gray100);
    }

    .nip-input {
      .input-wrapper {
        margin-bottom: 0.25rem;
      }
    }
    .google-maps-search {
      position: relative;

      .google-maps-results {
        list-style: none;
        background-color: var(--white);
        position: absolute;
        margin: 0;
        padding: 0;
        border-radius: 12px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        z-index: 2;
        max-height: 400px;
        overflow: hidden;
        overflow-y: scroll;
        width: 100%;

        .google-maps-result {
          padding: 15px 24px 14px 8px;
          border-radius: 12px;
          color: rgba(#000000, 80%);


          &:not(.no-results):hover {
            background-color: rgba(#0071FC, 5%);
            cursor: pointer;
          }
        }
      }
    }

    .phone-wrapper {
      margin-top: 0.5rem;
      .input-wrapper {
        width: 100%;
        input {
          margin-top: 0;
        }
      }
    }
  }

  .card-preview {
    position: sticky;
    top: 2rem;

    @media screen and (min-width: 1061px) {
      top: 6rem;
      align-self: flex-start;
    }

    @media screen and (max-width: 1060px) {
      order: -1;
      top: 0;
      background: white;
      padding: 16px 0;
      margin-top: -25px;
      border-bottom: 1px solid var(--gray200);
      z-index: 5;
    }
    @media screen and (max-width: 767px) {
      top: 72px;
    }
  }

  .card {
    background-color: var(--white);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-top: 1rem;
    width: 393px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;

    &.color-1 {
      background: var(--gray250);
    }

    &.color-2 {
      background: var(--brandBluePrimary);;
    }

    &.color-3 {
      background: var(--brandBlueSecondary);
    }

    &.color-4 {
      background: #F8D57A;
    }

    &.color-5 {
      background: #FFB673;
    }

    &.color-6 {
      background: #A4DF76;
    }

    &.color-7 {
      background: #60D472;
    }

    &.color-8 {
      background: #A779CC;
    }

    &.color-9 {
      background: #E593D8;
    }

    &.color-10 {
      background: #E98F9F;
    }

    .top-card {
      padding: 16px;
      height: auto;
      aspect-ratio: 2.4;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      background-size: cover;
      background-position: center;
      @media screen and (max-width: 991px) {
        padding: 12px;
      }

      .logo {
        height: 104px;
        width: 104px;
        background: var(--white);
        border-radius: 12px;
        background-size: cover;
        background-position: center;
        z-index: 2;
        @media screen and (max-width: 991px) {
          height: 78px;
          width: 78px;
        }
      }

      .address {
        align-self: flex-end;
        text-align: end;
        font-size: 1rem;
        font-weight: 500;
        z-index: 2;
        max-width: 70%;
        &.color-1 {
          color: var(--white);
        }
        &.color-2 {
          color: var(--black);
        }
      }

      .card-vertical-overlay {
        display: block;
        width: 393px;
        aspect-ratio: 2.4;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.2;
        background: linear-gradient(to bottom, var(--white) 0%, var(--black) 100%);
        @media screen and (max-width: 991px) {
          width: 300px;
        }
      }
    }

    .card-overlay {
      background-color: var(--white);
      opacity: 0.15;
      transform: rotate(60deg);
      display: block;
      width: 400px;
      height: 400px;
      position: absolute;
      left: 45%;
      top: -200px;
      z-index: 2;
    }

    .bottom-card {
      width: 100%;
      padding: 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: var(--white);
      z-index: 3;

      .card-label-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 4px;

        @media screen and (max-width: 991px) {
          gap: 3px;
        }

        p {
          font-size: 15px;
          letter-spacing: 2.4px;
          line-height: 18px;
          @media screen and (max-width: 991px) {
            font-size: 11px;
            letter-spacing: 2px;
            line-height: 13px;
          }
        }
      }

      img {
        height: 32px;
        width: 32px;
        @media screen and (max-width: 991px) {
          height: 24px;
          width: 24px;
        }
      }

      @media screen and (max-width: 991px) {
        padding: 12px;
      }
    }

    @media screen and (max-width: 991px) {
      width: 300px;
    }
  }

  .color-pick-wrapper {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    flex-wrap: wrap;

    .color-pick {
      border-radius: 40px;
      width: 24px;
      height: 24px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: opacity 200ms;

      &.selected {
        border: 1px solid var(--brandBluePrimary);
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .color-1 {
      background-color: var(--gray250);
    }

    .color-2 {
      background-color: var(--brandBluePrimary);
    }

    .color-3 {
      background-color: var(--brandBlueSecondary);
    }

    .color-4 {
      background-color: #F8D57A;
    }

    .color-5 {
      background-color: #FFB673;
    }

    .color-6 {
      background-color: #A4DF76;
    }

    .color-7 {
      background-color: #60D472;
    }

    .color-8 {
      background-color: #A779CC;
    }

    .color-9 {
      background-color: #E593D8;
    }

    .color-10 {
      background-color: #E98F9F;
    }

    .color-pick::before {
      content: "";
      width: 50px;
      height: 20px;
      background-color: white;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 2px;
      transform: rotate(45deg);
    }
  }

  .address-color-picker {
    .color-pick-wrapper {
      .color-1 {
        background-color: var(--white);
        border: 1px solid var(--gray200);
        &.selected {
          border: 1px solid var(--brandBluePrimary);
        }
      }
      .color-2 {
        background-color: var(--black);
      }
      .color-pick::before {
        background-color: var(--gray100);
      }
    }
  }

  .cashback-size-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
    @media screen and (max-width: 1060px) {
      justify-content: flex-start;
      gap: 1rem;
    }

    .cashback-size-pill {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0.5rem 0;
      width: 120px;
      height: 36px;
      border-radius: 49px;
      border: 1px solid var(--gray100);
      opacity: 0.6;

      img {
        margin-right: 1rem;
      }

      &.low {
        color: var(--navy)
      }

      &.medium {
        color: var(--brandBluePrimary)
      }

      &.high {
        color: var(--green)
      }

      &.selected {
        border: none;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        opacity: unset;
        transition: opacity 400ms;
      }
    }
  }

  .slider-value-container {
    .label {
      color: var(--gray200);
    }

    .slider-input-wrapper {
      background-color: white;
      border: solid 1px var(--gray300);
      border-radius: 1rem;
      width: 56px;
      height: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 8px 0;

      .label {
        color: var(--brandBluePrimary);
      }

      .input-wrapper {
        height: 0.8125rem;
        display: flex;
        width: 2.125rem;

        input {
          height: 0.8125rem;
          background-color: white;
          margin-top: 0;
          text-align: center;
          padding: 0;
          font-weight: 500;
          font-size: 0.8125rem;
          line-height: 0.8125rem;
          color: var(--brandBluePrimary);
        }
      }

    }
  }
}

.service-tile {
  width: 75px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 12px;
  color: rgba(black, 70%);
  background-color: var(--white);
  border: 1px solid var(--gray100);
  cursor: pointer;

  .category-label {
    font-size: 0.75rem;
    font-weight: bold;
    text-align: center;
    &.PROPERTY {
      font-size: 0.6rem;
    }
  }
}

.merchant-address {
  display: flex;
  flex-direction: row;
  width: 100%;

  .vertical-divider {
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
    min-width: 1px;
    background-color: var(--gray200);
  }

  input.form-control {
    border-radius: unset;
  }

  .input-wrapper:nth-of-type(1) {
    flex-basis: 50%;

    input.form-control {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  .input-wrapper:nth-of-type(2) {
    flex-basis: 25%;
  }

  .input-wrapper:nth-of-type(3) {
    flex-basis: 25%;

    input.form-control {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }
}