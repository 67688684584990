.block-1-4{
    flex-basis: 25%;
    max-width: 25%;
    @media (max-width: 610px) {
        flex-basis: 100%;
        max-width: 100%; 
    }
}
.block-1-3{
    flex-basis: 33%;
    max-width: 33%;
    @media (max-width: 520px){
        flex-basis: 100%;
        max-width: 100%;
    }
}
.block-2-3{
    flex-basis: 66%;
    max-width: 66%;
}
.block-1-2{
    flex-basis: 50%;
    max-width: 50%;
    @media (max-width: 610px) {
        flex-basis: 100%;
        max-width: 100%; 
    }
}
.block-1-1{
    flex-basis: 100%;
    max-width: 100%;
}


.block-2-1 {
    flex-basis: 50%;
    max-height: 50%;
}