$sidebar-width: 300px;
$mobile-sidebar-height: 72px;

.warning-bar-box {
  position: absolute;
  margin: 1rem 1rem 1rem 2.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .warning-bar {
    display: flex;
    border: 2px solid var(--lightred);
    z-index: 100;
    border-radius: 40px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    gap: 1rem;
    box-shadow: 0px 5px 5px #0000000f;
    background-color: var(--white);

    .warning-bar-content {
      display: flex;
      align-items: center;
      gap: 1rem;
      font-size: 14px;
      font-weight: 400;

      &:hover {
        opacity: 0.8;
      }
    }

    img.exit {
      &:hover {
        transform: scale(1.1);
      }
    }

    .deposit .deposit-amount {
      font-weight: 700;
      color: var(--lightred);
    }

    .return .number {
      font-weight: 700;
    }
  }
}


.nav-header:has(.nav-toggle.active) + .nav-content {
  display: block;
}

.nav-mobile {
  display: none;
  height: 40px;
  width: 40px;
  @media screen and (max-width: 1365px), (max-height: 705px) {
    display: block;
  }

  .nav-toggle {
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;

    span {
      margin-top: 17px;

      &:before {
        top: -10px;
      }

      &:after {
        bottom: -10px;
      }
    }

    &.active span {
      background-color: transparent;

      &:before,
      &:after {
        top: 0;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }

    span,
    span:before,
    span:after {
      border-radius: 1px;
      height: 3px;
      width: 30px;
      position: absolute;
      display: block;
      content: '';
      transition: all 300ms ease-in-out;
      background: var(--black);
    }
  }
}


.sidebar {
  background: var(--gray50);
  z-index: 5;
  @media (min-width: 1366px) and (min-height: 706px) {
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 0.5rem;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding: 1.5rem;
  }
  @media screen and (max-width: 1365px), (max-height: 705px) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    padding: 1rem 1.5rem;
    box-shadow: 0px 5px 5px #0000000f;
    .nav-header {
      display: flex;
      justify-content: space-between;
    }
  }

  .nav-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 2;
    @media screen and (max-width: 1365px), (max-height: 705px) {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      width: 300px;
      background: var(--gray50);
      border-radius: 0 0 5px 10px;
      padding: 1rem;
      max-height: calc(100vh - 72px);
      overflow-y: scroll;
    }
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  img.logo {
    max-width: 100%;
    cursor: pointer;
    @media (max-width: 991px), (max-height: 705px) {
      max-width: 150px;
    }
  }

  .list-nav {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      height: 2.5rem;
      line-height: 2.5rem;
      padding: 0 1rem;

      @media (max-height: 800px) and (min-height: 706px) {
        height: 2rem;
        line-height: 2rem;
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }

      &:hover a {
        color: rgba(black, 0.8);
      }

      &.active {
        background: var(--brandBluePrimary);
        border-radius: 12px;

        a, .link {
          color: var(--white);
        }
      }

      a, .link {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 1rem;
        color: rgba(black, 0.6);
        white-space: nowrap;
      }

      &.sidebar-cups-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &.active .sidebar-cups-count span {
          color: var(--white);
        }

        .sidebar-cups-count {
          display: flex;
          align-items: flex-end;
          gap: 4px;

          img {
            width: 14px;
            height: 18px;
          }

          span {
            font-size: 16px;
            line-height: 16px;
            color: var(--brandBluePrimary);
          }
        }
      }

      a.cups-link {
        justify-content: space-between;

      }
    }

    .list-icon {
      width: 1.5rem;
      aspect-ratio: 1;
    }
  }

  .nav-card {
    background: var(--brandBluePrimary);
    border-radius: 12px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    max-width: 255px;
    margin-top: 2em;
    margin-bottom: 2em;

    @media (max-height: 800px) and (min-height: 706px) {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .button-primary {
      color: var(--brandBluePrimary);
      background-color: var(--white);
    }

    div {
      display: flex;
    }

    h2 {
      font-size: 2.5rem;
      color: var(--white);
      margin: 0 0 2rem;
    }

    p {
      font-size: 1rem;
      color: var(--white);
      margin: 0;
    }

    img.settings-icon {
      margin-left: auto;
      cursor: pointer;
      transition: transform 0.5s ease;

      &.rotating {
        animation: rotate 2s linear infinite;
      }

    }

  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }


  .merchant-dropdown {
    position: relative;
    margin-bottom: 1.5rem;

    @media (max-height: 800px) and (min-height: 706px) {
      margin-bottom: 1rem;
    }

    .header {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-radius: 12px;
      padding: 0.5rem 1rem;
      background-color: var(--white);
      transition: background-color 0.3s ease;
      cursor: pointer;

      &.open, &:hover, &.active {
        background-color: var(--brandBluePrimary);
        color: var(--white);

        .chevron::before {
          border-color: var(--white);
        }
      }

      &.open {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      .chevron.up::before {
        top: -5px;
        left: 10px;
      }

      .chevron.down::before {
        top: 5px;
      }

      .merchant-details {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo {
          height: 3rem;
          width: 3rem;
          min-width: 3rem;
          margin-right: 1rem;
          border-radius: 12px;
          background-size: cover;
          background-position: center;
        }
      }

      .chevrons {
        min-width: 20px;
      }

      .title {
        word-break: break-word;
      }

    }

    .merchant-list {
      position: absolute;
      z-index: 1;
      overflow: hidden;
      margin-top: -1rem;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: var(--white);
      border-radius: 12px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 0.5rem;
      padding-top: 0;
      max-height: 0;
      transition: max-height 0.3s ease-out;

      &.open {
        max-height: 400px;
        overflow-y: scroll;
      }

      .merchant-list-item {
        padding: 15px 8px 14px 8px;
        margin-top: 0.25rem;
        border-radius: 12px;
        color: rgba(#000000, 60%);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background-color: rgba(#0071FC, 5%);
        }

        span, img {
          cursor: pointer;
        }

        &.merchant {
          cursor: pointer;
        }

        img.edit {
          width: 22px;
          height: 19px;
          margin-left: 1rem;
        }

        &.add {
          border: 1px solid var(--brandBluePrimary);

          &:hover {
            cursor: pointer;
          }

          margin-bottom: 0.5rem;
          margin-top: 1.5rem;
          padding-right: 0.5rem;

          span {
            font-size: 1rem;
            font-weight: 500;
            color: var(--brandBluePrimary);
          }
        }

        .merchant-point-label {
          display: flex;
          flex-direction: column;
          .name {
            font-weight: 700;
          }
        }
      }
    }

    &.disabled {
      .header {
        cursor: not-allowed;
        background-color: var(--gray200);
        color: var(--gray400);
      }
    }

  }
}

.loading-wrapper {
  width: calc(100% - $sidebar-width);
  height: 100%;
  position: absolute;
  background: rgba(#f5f8fa, 0.75);
  z-index: 5;
  @media screen and (max-width: 1365px), (max-height: 705px) {
    width: 100%;
  }

  .loading-screen {
    position: sticky;
    top: 0
  }
}