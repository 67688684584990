.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--gray50);
  border-radius: 12px 12px 0px 0px;

  .left-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    @media(max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 611px) {
      .searchbar {
        max-width: 150px;
        .searchbar-input {
          max-width: calc(150px - 12px - 24px - 8px - 12px); // searchbar_width - padding - img_width - gap - padding
        }
      }
      h3 {
        font-size: 16px
      }
    }

    h3 {
      text-wrap: nowrap;
    }

    .searchbar {
      height: 40px;
    }
  }

  @media(max-width: 520px) {
    gap: 0.5rem;
  }

  .table-button-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .filters, .download, .manage, .more-actions {
      align-items: center;
      justify-content: center;
      padding: 12px 16px;
      background-color: white;
      transition: 200ms;
      color: var(--gray300);
      border-radius: 24px;
      display: flex;
      gap: 8px;
      width: 100px;
      cursor: pointer;
      @media (max-width: 520px) {
        width: 40px;
        aspect-ratio: 1;
        padding: 12px;

      }

      &:hover {
        opacity: 0.6;
      }

      p.label-small {
        line-height: normal;
        @media (max-width: 520px) {
          display: none;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
    }

    .filters, .more-actions {
      background-color: white;
      border: 1px solid var(--gray200);
      color: var(--gray300);
      @media (max-width: 520px) {

      }
      border: 1px solid var(--gray200);

    }

    .manage {
      width: 180px;
      @media (min-width: 790px) and (max-width: 991px) {
        width: 150px
      }
      @media (max-width: 790px) {
        width: 40px;
        aspect-ratio: 1;
        padding: 12px;
        p.label-small {
          display: none;
        }
      }
    }

    .more-actions {
      width: 140px;
      @media (min-width: 790px) and (max-width: 991px) {
        width: 120px
      }
      @media (max-width: 790px) {
        width: 40px;
        aspect-ratio: 1;
        padding: 12px;
        p.label-small {
          display: none;
        }
      }
    }

    .download, .manage {
      background-color: var(--brandBluePrimary);
      color: white;
    }

    .download.disabled {
      cursor: not-allowed;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem; /* Ensure this matches your existing pagination margin */
}

.page-size-selector {
  display: flex;
  align-items: center;
}

.page-size-selector label {
  margin-right: 5px;
}

.pagination {
  list-style: none;
  padding: 0;
  margin: 0; /* Adjust the margin here to ensure alignment */
  display: flex;
  gap: 5px;

  .pagination-arrow {
    border: none;
    background: var(--brandBluePrimary);
    cursor: pointer;
    border-radius: 4px;
    width: 30px;
    height: 30px;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
    .chevron::before {
       border-color: var(--white);
    }
    .chevron.right::before {
      margin-right: 3px;
    }
    .chevron.left::before {
      margin-left: 3px;
    }

  }

  .current-page {
    padding: 5px 10px;
    margin: 0 5px;
  }
}

.pagination li {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: var(--white);
  color: var(--gray300);
  line-height: 1.5rem;
  cursor: pointer;
}

.pagination li:hover {
  color: black;
}

.pagination li.active {
  background: var(--brandBluePrimary);
  color: var(--white);
}

.table-content-wrapper {
  width: 100%;
  align-items: center;
  padding: 1.5rem;
  background-color: var(--gray50);
  border-radius: 0px 0px 12px 12px;

  .table-content {
    overflow-x: auto;
    max-height: 500px;
  }

  table {
    border-collapse: separate;
    border-spacing: 0 0.5rem;
    width: 100%;

    .table-content-header {
      background: none;
    }

    thead {
      position: sticky;
      top: 0;
      background: var(--gray50);
      z-index: 2;
    }

    td:first-child {
      border-top-left-radius: 12px;
    }

    td:last-child {
      border-top-right-radius: 12px;
    }

    td:first-child {
      border-bottom-left-radius: 12px;
    }

    td:last-child {
      border-bottom-right-radius: 12px;
    }

    th {
      color: var(--gray300);
      font-weight: 600;
      padding: 0 0.5rem 1rem 0.5rem;
      text-align: left;
      white-space: nowrap;
      @media (max-width: 520px) {
        padding: 0.5rem 0.25rem;
        font-size: 12px;
      }

      .sort-button {
        border-radius: 20px;
        background-color: var(--gray300);
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-left: 4px;
        position: relative;
        cursor: pointer;
        transition: 200ms;

        &:hover {
          opacity: 0.6;
        }

        &::before {
          content: "";
          border-style: solid;
          border-width: 0 3px 5px 3px;
          border-color: transparent transparent #ffffff transparent;
          transform: rotate(0deg);
          position: absolute;
          left: 5px;
          top: 2px;
        }

        &::after {
          content: "";
          border-style: solid;
          border-width: 0 3px 5px 3px;
          border-color: transparent transparent #ffffff transparent;
          transform: rotate(180deg);
          position: absolute;
          left: 5px;
          bottom: 2px;
        }
      }

      .sort-button.ascending {
        background-color: var(--brandBluePrimary);

        &::before {
          border-color: transparent transparent var(--brandGreen) transparent;
        }
      }

      .sort-button.descending {
        background-color: var(--brandBluePrimary);

        &::after {
          border-color: transparent transparent var(--brandGreen) transparent;
        }
      }
    }

    tr {
      background-color: white;
      border-radius: 24px;
      @media (max-width: 520px) {
        font-size: 12px;
      }

      &.with-error {
        opacity: 0.5;
      }
      &.correction {
        td, td.client-id-cell, td.cashback-status-cell {
          opacity: 0.9;
          color: var(--red)
        }
      }
    }

    tr.return {
      background-color: var(--gray100);
    }

    td {
      padding: 1rem 0.5rem;
      @media (max-width: 520px) {
        padding: 0.5rem 0.25rem;
        font-size: 12px;
      }

      .gray {
        color: var(--gray300);
      }
    }

    tr.with-error .actions-button-cell .actions-button {
      cursor: not-allowed;
    }

    .actions-button-cell {
      width: 30px;
    }

    .cell-digit {
      text-align: right;
    }

    .merchant-name {
      color: var(--brandBluePrimary);

      .chevron {
        margin-left: -20px;
        margin-right: 10px;
        cursor: pointer;

        &::before {
          border-color: var(--brandBluePrimary);
        }
      }
    }

    .inner-row {
      background-color: var(--gray100);
    }

    .clickable-cell {
      > span {
        color: var(--brandBluePrimary);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.table-tooltip p {
  font-size: 13px;
}