.modal-wrapper {
  position: fixed;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#f5f8fa, 0.75);
  z-index: 999;
}
.red{
  color: var(--lightred);
}
.gray{
  color: var(--gray300);
}

.modal-content {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 580px;
  max-width: 90vw;
  min-height: 500px;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;
  }

  h5 {
    margin-top: 0;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 28px;

    img.tooltip-icon {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      vertical-align: middle;
    }
  }

  .modal-close {
    cursor: pointer;
    width: 28px;
    height: 28px;

    &:hover {
      opacity: 0.5;
    }

    img {
      width: 28px;
      height: 28px;
    }
  }

  .modal-body {
    flex-grow: 2;
    padding: 0 2.5rem 1rem;

    .input-wrapper {

      .label-wrapper {
        label {
          font-size: 0.8125rem;
          font-weight: 500;
        }
      }

      input, textarea {
        margin-top: 0.5rem;
      }
    }

    .required-mark {
      color: var(--brandBluePrimary);
    }

    .select-wrapper {
      display: flex;
      flex-direction: column;

      .label {
        font-size: 0.8125rem;
        font-weight: 500;
      }

      div.ant-select.dropdown-select {
        width: 100%;
        margin-top: 0.5rem;
      }
      .date-picker-range-input {
        margin-top: 0.5rem;
      }
    }
  }

  .modal-footer {
    padding: 0 2.5rem 2.5rem;
    display: flex;
    gap: 1rem;

    &.with-error {
      flex-direction: column;
      margin-top: 16px;

      .buttons {
        gap: 1rem;
        display: flex;
        flex-direction: row;
      }
    }
  }

  .loading-screen {
    height: 400px;
  }

  @media screen and (max-width: 580px) {
    width: 100vw;
    height: 100vh;
    border-radius: unset;
    max-width: unset;
    .modal-header {
      padding: 2rem 1rem 0;
    }
    .modal-body {
      padding: 0 1rem 1rem;
    }
  }
}

.modal-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0;
  margin: 0 0 2rem 0;

  .modal-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 1rem 1.5rem;
    background-color: var(--gray50);
    border-radius: 0.75rem;

    .label-bold {
      font-size: 1rem;
      font-weight: bold;
    }

    .item-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.clickable {
      cursor: pointer;
    }

    &.selected {
      background-color: var(--brandBluePrimary);
      color: var(--white);
    }
  }
}


.opening-hours-modal {
  .modal-list-item {
    padding: 0.75rem 0.5rem 0.75rem 1.5rem;

    .ant-picker-range {
      height: 2rem;
      @media screen and (max-width: 580px) {
        width: 10rem;
      }

    }

    .closed {
      p {
        font-size: 14px;
        font-style: italic;
        line-height: 1rem;
        padding: 0.5rem 0;
      }

      .actions-button {
        padding-right: 0.5rem;
      }
    }
  }
}

.terminal-modal {
  p.note {
    margin-top: 0.5rem;
  }
}

.actions-button {
  padding: 0 1rem;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

}


.actions-modal-wrapper {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: rgba(black, 0.25);

  .actions-modal-content {
    box-shadow: 0 0 10px -5px rgba(black, 0.5);
    border-radius: 12px;
    padding: 0.5rem;
    background-color: var(--white);
    position: fixed;
    min-width: 150px;

    .action {
      padding: 0.5rem;
      width: 100%;
      min-width: max-content;
      border-radius: 0.25rem;

      &:hover {
        cursor: pointer;
        background-color: rgba(0,113,252,0.2 );

      }

      .delete {
        color: var(--red);
      }
    }
  }
}

.image-modal {
  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  h5 {
    margin: 0
  }

  min-height: unset;

  &.color {
    width: calc(45vh);
    height: calc(45vh);
  }

  &.offer-image,
  &.logo-image {
    width: calc(90vh);
    height: calc(90vh);

    .reactEasyCrop_Container {
      width: calc(90vh - 10rem - 40px);
      height: calc(90vh - 10rem - 40px);
    }

    @media screen and (max-width: 100vh) {
      width: 100vw;
      height: 100vh;
      max-width: unset;
      border-radius: unset;
      .reactEasyCrop_Container {
        width: calc(100vw - 2rem);
        height: calc(100vw - 2rem);
      }
    }
  }

  &.background-image {
    width: calc(90vh * 2.4);
    height: calc(90vh);

    .modal-body {
      padding: 0 2rem;
      display: flex;
    }

    .reactEasyCrop_Container {
      width: calc((90vh - 10rem - 40px) * 2.4);
      height: calc(90vh - 10rem - 40px);
    }

    @media screen and (max-width: 100vh) {
      width: 100vw;
      height: 100vh;
      max-width: unset;
      border-radius: unset;
      .modal-body {
        padding: 0;
      }
      .reactEasyCrop_Container {
        width: calc(100vw - 2rem);
        height: calc((100vw - 2rem) / 2.4);
      }
    }
  }

  .reactEasyCrop_Container {
    border: 1px solid var(--gray200);
    border-radius: 12px;
    position: relative;
  }
}


.service-types-modal {
  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service-types-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-template-rows: auto auto auto;
    grid-gap: 1rem;

    .service-tile {
      color: var(--gray200);

      &.active,
      &:hover {
        color: var(--white);
        background-color: var(--brandBluePrimary);
      }

      &.selected {
        color: var(--white);
        background-color: var(--brandBluePrimary);
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}

.merchant-data-modal {
  width: 473px;

  .modal-list {
    gap: 0.25rem;
    margin: 0;
  }

  .horizontal-divider {
    background-color: var(--gray300);
    height: 1px;
    margin-left: 0.5rem;
  }

  .modal-list-item {
    justify-content: flex-start;
    padding: 0.5rem;
    background-color: var(--white);
    border-radius: 0.25rem;
    cursor: pointer;

    input {
      margin-right: 0.5rem;
    }

    &:not(.disabled):hover {
      background-color: var(--gray100);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.management-modal {
  .invitation {
    margin: 8px;

    p.title {
      font-size: 14px;
      font-weight: 600;
    }

    input {
      margin-top: 10px;
      margin-bottom: 0;
    }

    button {
      margin-top: 10px;
    }

    p.hint {
      margin-top: 5px;
      font-size: 10px;
      font-weight: 400;
      color: var(--brandBluePrimary);
    }
  }

  .list {
    margin: 32px 8px 8px;
    overflow-y: auto;
    max-height: 30vh;

    p {
      font-size: 12px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.9);
    }

    .tile {
      width: 100%;
      height: 48px;
      margin-top: 8px;
      margin-bottom: 8px;
      background-color: var(--gray50);
      border-radius: 8px;
      padding: 12px;
      align-content: center;
      display: flex;
      flex-wrap: wrap;

      p {
        font-size: 12px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        margin-left: 8px;
      }

      p.superuser {
        font-weight: 700;
      }

      p.not-verified {
        font-style: italic;
      }

      img.dots {
        margin-left: auto;
        margin-right: 0;
        cursor: pointer;
      }

      div {
        display: grid;

        sub {
          margin-left: 8px;
          font-size: 10px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.popup {
  min-height: auto;
}

.password-modal {
  input {
    margin-top: 0.5rem;
  }

  button {
    margin-top: 1rem;
  }
}

.chart-details-modal {
  min-width: 850px;
  width: 70vw;
  @media screen and (max-width: 870px) {
    width: 100vw;
    min-width: unset;
    height: 100vh;
    border-radius: unset;
    max-width: unset;
    .modal-header {
      padding: 2rem 1rem 0;
    }
    .modal-body {
      padding: 0 1rem 1rem;
    }
  }

  .modal-header {
    flex-direction: column;
    gap: 8px;

    .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 1rem;

      h5 {
        margin: 0;
        line-height: 40px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }

      .buttons-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }

      .modal-close {
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    .button {
      max-width: 105px;
    }

    .header-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      flex-wrap: wrap;

      .active-client-interval-select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
      }
    }

  }

  .modal-body {
    padding: 48px;
    overflow-y: scroll;
    display: flex;
    align-items: center;
    justify-content: center;

    .pie-chart-section {
      @media (max-width: 550px) {
        flex-direction: column;
        gap: 16px;
      }

      .pie-chart-legend {
        @media (max-width: 550px) {
          width: 100%;
        }
      }
    }

    .chart-section {
      .chart-selectors {
        .chart-legend {
          @media (max-width: 550px) {
            display: none;
          }
        }
      }

      .chart-section-bottom {
        @media (min-width: 550px) {
          display: none;
        }
      }
    }

    .no-chart {
      height: 320px;
    }
  }
}


.statistics-ranges-modal {
  width: 473px;

  .modal-header {
    h5 {
      margin-bottom: 2px;
    }

  }

  .modal-body {
    padding-bottom: 1.5rem;

    .subtitle {
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
  }
}

.filters-modal .modal-body {
  max-height: 70vh;
  overflow-y: auto;

  .button {
    margin-top: 32px;
  }
}

.filters-modal,
.contact.user-message {
  .modal-body {
    .section,
    .admin-message .section {
      h6 {
        font-size: 14px;
        font-weight: 600;
        color: var(--black);
        margin-bottom: 8px;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 8px;

        span {
          cursor: pointer;
          font-size: 13px;
          font-weight: 600;
          color: var(--brandBluePrimary);
          transition: color 0.2s;

          &:hover {
            color: var(--brandBlueSecondary);
          }
        }

      }

      div.ant-select.dropdown-select {
        margin-top: 8px;
        width: 100%;
      }

      .status, .partner-type {
        margin-top: 16px;
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;

        button {
          border: 1px solid var(--gray100);
          background-color: inherit;
          border-radius: 49px;
          padding: 8px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);
          transition: box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
          gap: 8px;

          > div.icon-container {
            width: 20px;
            height: 20px;
            background-color: var(--gray50);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          span {
            font-size: 13px;
            font-weight: 500;
            color: var(--brandBluePrimary);
            text-wrap: nowrap;
          }
        }

        button.active {
          box-shadow: 0 0 10px -5px rgba(black, 0.5);
          opacity: 1;
        }

        button:hover, button:active {
          cursor: pointer;
          box-shadow: 0 0 10px -5px rgba(black, 0.5);
          opacity: 1;
        }
      }

      div.border-values {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 13px;
          font-weight: 600;
          color: var(--brandBluePrimary);
        }
      }

    }

    .section.datepicker {
      h6 {
        margin-bottom: 16px;
      }
    }

  }
}

.map-modal {
  width: 700px;

  .modal-body {
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .map-container {
      width: 100%;
      height: 400px;
    }

    .address-form {
      width: 100%;

      label {
        font-size: 0.8125rem;
        font-weight: 500;
        margin-bottom: 4px;
      }

      .merchant-address {
        margin-top: 0.5rem;
      }
    }

    .map-slider-wrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 16px;
      .map-slider{
        width: 80%;
        margin-right: 8px;
      }
    }

  }
}
.checkbox-all {
  height: 64px;
  background-color: var(--white);
  border-radius: 8px;
  padding: 12px 16px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  input {
    width: 16px;
    height: 16px;
  }
  label{
    cursor: pointer;
  }
}

.checkbox {
  height: 64px;
  width: 100%;
  background-color: var(--gray50);
  border-radius: 8px;
  padding: 12px 16px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  input {
    width: 16px;
    height: 16px;
  }

  label{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .user-icon{
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background-color: var(--white);
      padding: 8px;
    }

    p{
      font-family: Roboto, sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: var(--black);
    }
    .w700{
      font-weight: 700;
    }
  }
}

.manager-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 700px;
  max-width: 90vw;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      margin-bottom: 32px;
      display: flex;
      flex-direction: column;

        h5 {
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 28px;
        }

      .modal-title {
        margin-bottom: 8px;
        margin-right: 8px;
      }
      .tags-wrapper{
        display: flex;
        flex-direction: row;
        gap: 4px;
        .tag{
          width: 100%;
          height: 20px;
          padding: 3px 12px;
          border: 1px solid var(--gray200);
          border-radius: 11px;
          color: var(--gray200);
          font-family: Roboto, sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          cursor: pointer;
        }
        .active{
          color: white;
          background-color: var(--brandBluePrimary);
          border: 1px solid var(--brandBluePrimary);
        }
      }
    }
  }

  .modal-body {
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 2;
    padding: 0 2.5rem 2.5rem;
    height: calc(80vh - 5em);
    overflow-y: auto;
    width: 100%;
    .section{
      width: 100%;
      .label{
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color:var(--gray300);
      }
      .red{
        color: var(--lightred);
      }
      .list-wrapper{
        display: flex;
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 24px;
        gap: 8px;
        .offer{
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: var(--gray50);
          border-radius: 12px;
          height: 48px;
          width: 100%;
          border: 1px solid var(--gray50);
          padding: 0 24px;
          .title{
            overflow: hidden;
            justify-items: flex-start;
            width: 60%;
          }
          .title p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }
          .info{
            justify-items: flex-end;
            width: 40%;
            margin-right: 24px;
          }

          p{
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: var(--black);
          }

          span{
            font-family: Roboto, sans-serif;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
          }
          .blue{
            color: var(--brandBluePrimary);
          }
          .gray{
            color: var(--gray300);
          }
          .action-dots{
            cursor: pointer;
          }
        }
        .red {
          border: 1px solid var(--lightred);
        }
      }
    }
  }
}

.pay-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 580px;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;

        h5 {
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 28px;
        }

      .modal-title {
        margin-bottom: 0;
        margin-right: 8px;
      }
      .mb8 {
      margin-bottom: 8px;
      }
    }
  }

  .modal-body {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 2;
    padding: 0 2.5rem 2.5rem;
    overflow-y: auto;
    width: 100%;
    .section{
      width: 100%;
      .label {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }
      .w700 {
        font-weight: 700;
      }
      .label-margin{
        margin-bottom: 8px;
      }
      .input {
        border-radius: 20px;
        background-color: var(--gray50);
        border: 0;
        min-height: 40px;
        padding: 5px 10px;
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: row;
        .input-text {
          background-color: var(--gray50);
          border: 0;
          width: 100%;
        }
      }
      .offer-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
          align-items: center;
      }
      .blue{
        color: var(--brandBluePrimary);
      }
      .with-border {
        border-bottom: 1px solid var(--gray100);
        margin-bottom: 8px;
      }
    }
  }
}

.extend-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 580px;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;

    .title-wrapper {
      margin-bottom: 32px;
      display: flex;
      flex-direction: row;

        h5 {
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 28px;
        }

      .modal-title {
        margin-bottom: 0;
        margin-right: 8px;
      }
      .mb8 {
      margin-bottom: 8px;
      }
    }
  }

  .modal-body {
    gap: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 2;
    padding: 0 2.5rem 2.5rem;
    overflow-y: auto;
    width: 100%;
    .section{
      width: 100%;
      .select-wrapper{
        margin-bottom: 32px;
        .label-margin{
          margin-bottom: 8px;
        }
      }
    }
  }
}

.offer-success-screen {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 700px;
  max-width: 90vw;
  min-height: 580px;
  background: white;

  .success-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 96px;
    margin-bottom: 24px;

    p {
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: black;
      opacity: 0.7;
      margin-left: 68px;
      margin-right: 68px;
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 8px;
  }
}

.end-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 348px;
  background: white;
  padding: 16px;

  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      width: 64px;
      height: 64px;
      margin-bottom: 16px;
    }
    .title-wrapper {
      margin-bottom: 4px;
      display: flex;
      flex-direction: column;

        h2 {
        margin-top: 0;
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        }
      .blue {
        color: var(--brandBluePrimary);
      }

      .modal-title {
      margin-bottom: 0;
      }
    }
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 2;
    p{
      font-family: Roboto, sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 24px;
    }

    .button-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
  }

}

.users-modal {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 700px;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .modal-close {
      height: 40px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }



    .title-wrapper {
      display: flex;
      flex-direction: column;

        h5 {
        margin-top: 0;
        font-size: 1.5rem;
        line-height: 28px;
        }

      .modal-title {
      margin-bottom: 0;
      }
      .mb8 {
      margin-bottom: 8px;
      }
    }
  }

  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .users-wrapper{
    gap: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-grow: 2;
    padding: 0 2.5rem 1rem;
    max-height: calc(80vh - 5em);
    overflow-y: auto;
    width: 100%;

    }

    .search-wrapper{
      padding: 0 2.5rem 0;
      width: 100%;
    }

    .input {
          width: 100%;
          border-radius: 20px;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;
          height: fit-content;
          margin-bottom: 24px;
        }

    .text-gray {
          color: var(--gray300);
        }
    .input-text {
      height: auto;
      resize: none;
      background-color: var(--gray50);
      width: 90%;
      border: 0;
      padding: 5px 15px;
      margin-left: 20px;
      overflow: hidden;
    }
    .icon {
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }

    .checkbox-wrapper {
      display: flex;
      width: 100%;
    }
    .checkbox-wrapper-all {
      display: flex;
      width: 100%;
      padding: 0 2.5rem 0;
      justify-content: flex-start;
      align-items: center;
    }
  }
}

.target-group-modal {
  width: 700px;

  .target-group-form {
    padding: 1rem 5rem 0;

    @media (max-width: 800px) {
      padding: 1rem 2rem 0;
    }
  }

  .modal-body {
    overflow-y: scroll;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .button-center {
      display: flex;
      justify-content: center;

      .button {
        min-width: 225px;
        width: unset;
        font-weight: normal;
        font-size: 13px;
      }
    }

    .modal-list {
      .modal-list-item.selected {
        .clickable-name:hover {
          color: white;
          opacity: 0.4;
        }
      }

      .modal-list-item {
        padding: 0;

        .clickable-name {
          padding: 1rem 0rem 1rem 1.5rem;
          cursor: pointer;
          width: 100%;

          &:hover {
            color: var(--brandBluePrimary);
          }
        }

        .actions-button {
          padding: 1rem 1.5rem;
        }
      }
    }
  }
}


.cups-management-modal {
  min-height: 300px;

  .input-wrapper {
    margin-bottom: 1rem;
  }

  .button {
    margin-bottom: 2rem;
  }
}

.admin-message-modal {
  min-width: 625px;
}


.controls-modal {
  min-height: 300px;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .target-group-container {
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
}

.modal-content.deposit .modal-body div.success-screen {
  margin-top: 75px;
  margin-bottom: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 40px;
    width: 75%;
  }

}

.modal-content.deposit .modal-body .input-wrapper {
  margin-bottom: 1.5rem;
}

.modal-content.deposit-history {
  max-height: 70%;
}

.modal-content.deposit-history .modal-header {
  flex-direction: column;
  border-bottom: 1px solid var(--gray200);
  padding-bottom: 1rem;

  .title-row {
    display: flex;
    justify-content: space-between;
  }

  .cash-row {
    display: flex;

    .cash-block {
      background-color: var(--brandBluePrimary);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      padding: 8px 16px 8px 16px;
      gap: 4px;

      &:first-of-type {
        margin-right: 1rem;
      }

      .title {
        color: var(--white);
        font-size: 16px;
        font-weight: 500;
      }

      .amount {
        color: var(--white);
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}

.modal-content.deposit-history .modal-body {
  overflow-y: auto;

  .history-list {

    .history-group {
      margin-top: 2rem;
    }

    .history-group span.title {
      font-size: 14px;
      font-weight: 600;
      color: var(--gray300);
      margin-top: 2rem;
    }

    .history-item {
      display: flex;
      background-color: var(--gray50);
      border-radius: 12px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      padding: 0.5rem;

      .history-icon {
        width: 56px;
        height: 56px;
      }

      .history-info {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;
        justify-content: center;

        span {
          font-size: 14px;
        }

        span:first-child {
          font-weight: 400;
        }

        span:nth-child(2) {
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
        }

        span:last-child {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      .history-total {
        margin-right: 0;
        margin-left: auto;
        align-items: center;
        display: flex;
        color: var(--brandBluePrimary);
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.modal-content .modal-body .success-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 75px 1.5rem;

  span.title {
    margin-top: 3rem;
    font-size: 20px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.9);
  }

  span.desc {
    margin-top: 1rem;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.7);
  }

  button {
    margin-top: 40px;
    width: 75%;
  }

}

.modal-content.returns-modal {
  max-height: 70%;

  .modal-header {
    display: flex;
    justify-content: space-between;
  }

  .modal-body {
    overflow-y: auto;

    .returns-item {
      background-color: var(--gray50);
      border-radius: 12px;
      padding: 0.5rem;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.non-clickable {
        cursor: inherit;
      }

      .content {
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
        gap: 0.2rem;

        span.name {
          font-size: 14px;
          font-weight: 400;
          color: var(--brandBluePrimary);
        }

        span.amount {
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          margin-bottom: 0.2rem;
        }

        span.date {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
        }
      }

      div.arrow {
        margin-left: auto;
        margin-right: 0;
      }
    }

    .transactions {
      .transaction-item-list {
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--gray200);

        span:first-child {
          font-size: 18px;
          font-weight: 500;
        }

        span:nth-child(2) {
          color: var(--gray250);
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }

        span:last-child {
          font-size: 18px;
          font-weight: 500;
          color: var(--brandBluePrimary);
        }
      }
    }
  }
}

.admin-service-type-modal {
  min-height: unset;

  .section {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    h6 {
      margin: 0
    }

  }
}

.modal-content.settings {
  .modal-header {
    text-align: center;
    margin-bottom: 2rem;

    div:first-of-type {
      width: 28px;
    }

    .modal-title {
      margin-bottom: 0;
    }

    .email {
      font-size: 14px;
      font-weight: 400;
      color: var(--gray300);
    }
  }
}

.transaction-group,
.cup-action-group {
  margin-top: 2rem;

  span.title {
    font-size: 14px;
    font-weight: 600;
    color: var(--gray300);
    margin-top: 2rem;
  }
}

.transaction-group:last-of-type,
.cup-action-group:last-of-type {
  padding-bottom: 3rem;
}

.transaction-group > .transaction-item,
.cup-action-group > .cup-action-item {
  background-color: var(--gray50);
  display: flex;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 12px;
  align-items: center;

  div.icon {
    width: 56px;
    height: 56px;
    border-radius: 8px;

    img {
      width: 56px;
      height: 56px;
      border-radius: 8px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    gap: 0.2rem;

    span.date {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.8);
    }

    span.name {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.transaction-group > .transaction-item {
  cursor: pointer;

  &.selected {
    border: 2px solid var(--brandBluePrimary);
  }

  &.not-selectable {
    cursor: unset;
  }

  .content {
    span.amount {
      font-size: 14px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      margin-bottom: 0.2rem;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span.cashback {
        font-size: 14px;
        font-weight: 700;
        color: var(--brandBluePrimary);
        display: inline-flex;

        .rejected {
          color: var(--red);
        }

        img {
          margin-right: 0.3rem;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
}

.cup-action-group > .cup-action-item {
  .content {
    span.cup-action-type {
      font-size: 14px;
      color: var(--brandBluePrimary);

      span.bold {
        font-weight: 700;
      }
    }
  }
}

.app-user-details-modal {
  max-height: 90vh;

  .user-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4px;

    > div {
      background-color: var(--brandBluePrimary);
      padding: 5px 10px;
      border-radius: 20px;
      color: white;
      font-size: 12px;

      .title {
        font-weight: normal;
      }

      .value {
        font-weight: bold;
      }
    }
  }

  .user-history-header {
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--gray200);

    .modes {
      display: flex;
      flex-direction: row;
      gap: 12px;
      margin-bottom: 12px;

      .mode-title {
        font-size: 18px;

        &.selected {
          font-weight: 500;
        }

        &.not-selected {
          color: var(--gray250);
          cursor: pointer;
        }
      }

      .horizontal-divider {
        width: 2px;
        background-color: var(--gray250);
        margin: 4px 0;
      }
    }

    .user-history-filters {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .searchbar {
        height: 40px;
        max-width: unset;
      }
    }

    .cashback-status {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 1rem;

      .cashback-status-pill {
        background-color: var(--gray50);
        width: 156px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        padding: 8px 0;

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;

          .dot {
            width: 1rem;
            min-width: 1rem;
            height: 1rem;
            border-radius: 0.5rem;
            display: inline-block;

            &.blue {
              background-color: var(--brandBlueSecondary);
            }

            &.green {
              background-color: var(--brandGreen);
            }

            &.gray {
              background-color: var(--gray300);
            }
          }

          > div {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .title {
              font-size: 14px;
              font-weight: normal;
              margin: 0px
            }

            .value {
              font-size: 18px;
              font-weight: bold;
            }
          }

        }
      }
    }

  }

  .user-history-list {
    overflow-y: auto;
    max-height: 45vh;

    .button {
      position: sticky;
      bottom: 20px;
    }
  }
}


.confirm-change-modal {
  width: 350px;
  padding: 1rem;
  gap: 1.5rem;
  
  @media screen and (max-width: 580px) {
    width: 100vw;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    text-align: center;

    img {
      width: 64px;
      height: 64px;
      margin-bottom: 1rem;
    }

    .title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 0.25rem;
    }

    .body {
      font-size: 12px;
    }
  }

  .modal-footer {
    padding: 0;
  }
}

.modal-content.accounting {
  width: 80vw;
}

.cell-digit .dots {
  padding-right: 5px;
  cursor: pointer;
}