.onboarding {
  display: flex;
  align-items: stretch;
  width: 100%;

  justify-content: center;
  @media (max-width: 991px) {
    flex-direction: column;
  }
  @media (min-width: 992px) {
    min-height: 100vh;
  }

  .onboarding-content,
  .onboarding-sidebar {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
      flex-basis: 50%;
    }
    @media screen and (min-width: 1200px) {
      padding: 5%;
    }
    @media screen and (min-width: 1600px) {
      padding: 10%;
    }
  }

  .onboarding-sidebar {
    position: relative;
    flex-grow: 2;
    background: rgb(0, 113, 252);
    background: linear-gradient(225deg, rgba(0, 113, 252, 0.65) 0%, rgba(0, 113, 252, 1) 100%);
    color: var(--white);
    padding: 80px 5%;
    @media (min-width: 1600px) {
      padding-left: 10%;
      padding-right: 10%;
    }
    @media (max-width: 991px) {
      flex-direction: row;
      order: 2;
      gap: 20px;
      padding: 80px 20px 40px;
      div {
        padding: 0 20px;
      }
    }
    @media (min-width: 992px) {
      align-items: center;
      h1, p, a {
        text-align: center;
      }
    }

    .brand-logo {
      position: absolute;
      color: transparent;
      left: 20px;
      top: 20px;
      @media (max-width: 991px) {
        display: none;
      }
      @media (min-width: 768px) {
        width: 229px;
        height: 62px;
      }
    }

    .onboarding-image {
      margin-left: 50px;
      @media (max-width: 1599px) {
        max-width: 300px;
      }
      @media (max-width: 991px) {
        max-width: 30vw;
      }
    }

    a {
      display: block;
      color: var(--white);
      text-decoration: none;
      font-weight: bold;
      padding-right: 30px;
      font-size: 1.25rem;

      .arrow {
        position: relative;
        width: 30px;
        display: inline-block;
        vertical-align: top;
        margin-top: 5px;
        margin-left: 5px;
      }

      .arrow:after,
      .arrow:before {
        display: block;
        content: "";
        position: absolute;
        right: 0;
      }

      .arrow:before {
        height: 1px;
        width: 25px;
        background-color: var(--white);
        top: 7px;
      }

      .arrow:after {
        width: 0;
        height: 0;
        top: 2px;
        border-top: 6px solid transparent;
        border-left: 9px solid white;
        border-bottom: 6px solid transparent;
      }
    }
  }

  .onboarding-content {
    @media (min-width: 992px) {
      max-width: 40vw;
      padding: 30px;
    }
    @media (max-width: 991px) {
      padding: 50px;
    }

    &.verify-telephone {
      form {
        width: 312px;
      }
    }

    img.logo {
      margin-bottom: 4rem;
    }

    .loader {
      margin-bottom: 1rem;
    }

    .brand-logo {
      margin-bottom: 3rem;
      @media (max-width: 991px) {
        max-width: 200px;
      }
    }

    h2 {
      text-align: center;
    }

    h2 + p {
      text-align: center;
      font-size: 1rem;
      line-height: 1.3;
      color: rgba(var(--black), 0.7);
      margin-bottom: 2rem;
      margin-top: 0;

      &.note {
        max-width: 312px;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 312px;

      .form-control {
        padding-left: 50px;
        padding-right: 10px;
        width: 100%;
      }

      .sign-up-hint, .send-again-hint {
        margin-top: 1.5rem;
        color: var(--gray300);
        font-size: 0.75rem;
      }

      .send-again-hint {
        text-align: center;
        margin-bottom: 0;

        & + .link {
          font-size: 0.75rem;
        }

        &.warning {
          color: var(--red);
        }
      }

      .sign-up-hint a {
        margin-left: 5px;
      }

      .input-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        &:not(:last-child) {
          margin-bottom: 1rem;
        }

      }

      .input-wrapper .icon-wrapper {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        display: flex;
        background-color: var(--gray100);
        border-radius: 20px;
        justify-content: center;
        align-items: center;

        img.icon {
          width: 12px;
          height: 12px;

          &.fullName {
            position: absolute;
            top: 2px;
            width: 18px;
            height: 18px;
          }

          &.telephoneNumber, &.code {
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .button:last-of-type {
      margin-top: 0.5rem;
    }

    .accept-rules-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;

      .accept-rules-text {
        margin-left: 0.5rem;
        color: var(--gray300);
        font-size: 0.75rem;

        a {
          text-decoration: underline;
        }
      }
    }

    .bank-grid {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 24px 0;

      .bank-grid-error {
        color: var(--red);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        transition: color 0.3s ease;
        text-align: center;
      }

      .bank-grid-row {
        display: flex;
        flex-direction: row;
        gap: 8px;

        .bank-grid-icon {
          width: 120px;
          height: 80px;
          border-radius: 12px;
          border: 1px var(--gray100) solid;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            opacity: 0.6;
          }

          img {
            width: 119px;
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

        }
      }
    }
  }
}

.password-validation-container {
  margin-bottom: 10px;
  margin-right: auto;

  p {
    font-size: 12px;
    color: var(--red);
    line-height: 1.5;
    transition: color 0.3s ease;
  }

  p:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 5px;
    margin-bottom: 1px;
    vertical-align: middle;
    transition: opacity 0.3s ease;
  }

  p.active {
    color: var(--brandBluePrimary)
  }

  p.active:before {
    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2211%22%20height%3D%228%22%20viewBox%3D%220%200%2011%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1.23096%203.02393L4.19599%205.92308L9.23096%201%22%20stroke%3D%22%230071FC%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  }

}

.country-select-popup {
  width: 300px !important;
}

.country-select-popup-box {
  display: flex;
  align-items: center;

  span:first-child {
    margin-right: 8px;
  }

  span:nth-child(2) {
    margin-right: 6px;
  }
}

.phone-wrapper {
  display: flex;
}

.phone-wrapper .input-wrapper {
  margin-left: 5px;
}

.phone-wrapper .ant-select {
  height: 100%;
}

.phone-wrapper .ant-select .ant-select-selector {
  border-radius: 20px;
  border: 1px solid var(--gray100);
}

.account-numbers {
  margin-bottom: 20px;

  ul {
    display: inline;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      border: 1px solid var(--gray100);
      border-radius: 8px;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        background-color: var(--gray100);
      }

      img {
        max-width: 60px;
        max-height: 40px;
        margin-right: 10px;
      }
    }
  }


}