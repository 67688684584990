
.map-container {
  border-radius: 12px;
  position: relative;

  .map-view {
    width: 100%;
    height: 540px;
    borderRadius: 12px;
    >div {
      border-radius: 12px;
    }
  }

  .map-panel {
    position: absolute;
    max-height: calc(540px - 3rem);
    top: 1.5rem;
    right: 1.5rem;
    padding: 1.5rem;
    border-radius: 12px;
    background-color: var(--white);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 400px;

    .header {
      margin-bottom: 0.5rem;

      h5 {
        font-size: 1.125rem;
        margin: 0;
        color: var(--black);

        &.un-selected {
          color: var(--gray250);
        }
      }
    }

    .map-controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .searchbar {
        padding: 7px 16px;
      }
    }

    .map-list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      overflow-y: scroll;

    }
  }
}

.map-item-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--gray50);
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  .left-section {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .logo {
      border-radius: 8px;
      width: 56px;
      height: 56px;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name-address-container {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .name {
          font-size: 14px;
          font-weight: normal;
        }

        .address {
          font-size: 12px;
          font-weight: normal;
          width: 250px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .bottom-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .category {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          .category-label {
            font-size: 12px;
            font-weight: normal;
          }
        }

        .date {
          font-size: 12px;
          font-weight: normal;
        }

      }
    }
  }

  .right-section {
    display: flex;
    flex-direction: row;
  }

  &.point {
    .right-section {
      align-items: center;
    }
  }
}


.map-info-window {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .map-info-window-details {
    display: flex;
    flex-direction: row;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      background-color: var(--gray50);
      border-radius: 20px;
      padding: 0.5rem;

      span {
        font-weight: 500;
        font-size: 0.875rem;

        .description {
          font-weight: normal;
          //font-size: 0.875rem;
        }
      }

      img {
        height: 20px;
        width: 20px;
      }

      .icon-container {
        width: 20px;
        height: 20px;
        background-color: var(--white);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 11px;
          width: 11px;
        }
      }
    }
  }
}