.dashboard-offers-content{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  padding: 0;

  @media (max-width: 900px) {
    gap: 1rem;
  }

    &.with-sticky-bar {
    padding-top: 0;
  }

  h2 {
    font-size: 2rem;
    margin: 0;
  }

  h3 {
    font-size: 1.5rem;
    margin: 0;

    &.dashboard-h3 {
      font-size: 24px;
      @media (max-width: 1156px) {
        font-size: 16px;
      }
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 21px;
    margin: 0;
  }

  .group-details{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
  .label{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    justify-content: flex-start;
    .start{
      justify-content: flex-start;
    }
    .end{
      justify-content: flex-end;
    }
  }

  .small-label{
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
  .mb4{
    margin-bottom: 4px;
  }
  .mr4{
    margin-right: 4px;
  }

  .mb8{
    margin-bottom: 8px;
  }
  .mb16{
    margin-bottom: 16px;
  }
  .mt16{
    margin-top: 16px;
  }

  .fit-height{
    height: fit-content;
  }

  .max-height-320{
    max-height: 320px;
  }

  .w700{
    font-weight: 700;
  }

  .blue{
    color: var(--brandBluePrimary);
  }

  .grey{
    color: var(--gray200);
  }

  .one-line{
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .label-group {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 8px;
  }
  .half{
    flex-basis: 50%;
  }
  .label-title{
    margin-bottom: 4px;
    .icon-content{
      display:flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .icon {
        height: 18px;
        width: 18px;
      }
    }
  }

  .responsive-wrapper {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 8px;
    .responsive-label-wrapper {
      background-color: var(--white);
      color: var(--black);
      border-radius: 8px;
      padding: 12px 8px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-basis: 50%;
    }
    @media (max-width: 1440px) {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .responsive-label-wrapper {
        background-color: var(--white);
        color: var(--black);
        border-radius: 8px;
        padding: 12px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-basis: 100%;
      }
    }
  }

  .label-wrapper{
    background-color: var(--white);
    color: var(--black);
    border-radius: 8px;
    padding: 12px 8px;
    margin-bottom: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .icon-content{
      display:flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      .icon {
        height: 18px;
        width: 18px;
      }
    }

    .content-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: 4px;
      gap: 8px;
    }
  }
  .time-left-wrapper{
    background-color: var(--white);
    color: var(--black);
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 12px 8px 12px 20px;

  }

  .details-offer-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }

  .details-offer-content {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .details-media{
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    .media{
      width: 198px;
      border-radius: 12px;
      aspect-ratio: 16/9;
      margin-bottom: 8px;
      object-fit: fill;
    }
    .media-vertical{
      width: 198px;
      border-radius: 12px;
      aspect-ratio: 9/16;
      margin-bottom: 8px;
      object-fit: fill;
    }
    .media-horizontal{
      width: 198px;
      border-radius: 12px;
      aspect-ratio: 16/9;
      margin-bottom: 8px;
      object-fit: fill;
    }
  }



  .header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 0.5rem;

    .header-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      @media (max-width: 610px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .button {
        width: max-content;
      }

      &.offer-button-wrapper {
        .button {
          min-width: 206px;
        }

        @media (max-width: 610px) {
          flex-direction: column;
        }
      }
    }
    .button-wrapper{
      .icon-button{
        padding: 0 1rem;
        width: auto;
        max-width: none;
        img{
          border: solid 1px white;
          border-radius: 30px;
          margin-right: 0.5rem;
          vertical-align:middle;
          margin-top: -3px;
        }
      }
    }
  }

  .card-block-wrapper, .chart-block-wrapper {
    .block {
      width: 100%;

      &.big-chart-block {
        .chart-section {
          width: 80%;
        }
      }

      .chart-section {
        .chart-selectors {
          .chart-legend {
            @media (max-width: 767px) {
              display: none;
            }
          }
        }

        .chart-section-bottom {
          @media (min-width: 767px) {
            display: none;
          }
        }
      }
    }

    @media (max-width: 900px) {
      flex-direction: column;
      .block:not(.chart-block) {
        height: 242px;
      }
    }
    @media (max-width: 610px) {
      .block.chart-block {
        &.big-chart-block {
          .chart-section {
            width: 100%;
          }
        }

        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }

  .block-wrapper{
     display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    align-items: stretch;
    @media (max-width: 900px) {
      gap: 1rem;
    }

    &.block-wrapper-statistics {
      @media (max-width: 610px) {
        flex-direction: column;
      }
    }

    &:last-of-type {
      @media (max-width: 610px) {
        flex-direction: column;
      }
    }

    @media (max-width: 520px) {
      flex-direction: column;
    }

    .card {
        width: 346px;
        border-radius: 12px;
        @media screen and (max-width: 610px) {
          width: 100%;
        }

        .card-vertical-overlay {
          @media screen and (max-width: 991px) {
            width: 346px;
          }
          @media screen and (max-width: 610px) {
            width: 100%;
          }
        }

        .top-card {
          padding: 12px;

          .logo {
            height: 78px;
            width: 78px;
          }
        }

        .bottom-card {
          padding: 12px;

          .card-label-container {
            gap: 3px;

            p {
              font-size: 13px;
              letter-spacing: 2px;
              line-height: 13px;
            }
          }

          img {
            height: 24px;
            width: 24px;
          }
        }
      }

      .card-header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
      }

    .block {
      background-color: var(--gray50);
      border-radius: 12px;
      padding: 1.5rem;
      gap: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .value-section {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .value-wrapper {
          &.no-timerange {
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            align-items: flex-end;
            @media screen and (max-width: 1440px), (max-height: 706px) {
              flex-direction: column;
              align-items: flex-start;
            }

            .change-pill {
              margin: 0;
            }

            .standard-value {
              font-size: 2.5rem;
              line-height: 2.5rem;
              text-wrap: nowrap;
            }
          }

          .value-icon-wrapper {
            display: flex;
            gap: 0.5rem;

            .cup-icon {
              margin-top: 10px;
              width: 1.875rem;
              height: 2.175rem;
            }
          }

          .standard-value {
            display: block;
          }

          .change-pill {
            margin-top: 0.5rem;
          }

          .label-2 {
            margin-top: 0.25rem;
          }

          .change-timeframe {
            color: var(--gray250);
          }
        }

        .small-block-title {
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          z-index: 3;

          @media screen and (max-width: 1440px), (max-height: 706px) {
            font-size: 16px;
          }
        }
      }
    }

    .block-1-2, .block-1-3, .block-1-4 {
      align-items: stretch;
    }

    .block-1-4 {
      flex-basis: 25%;
      max-width: 23%;

      .value-section {
        .value-wrapper {
          .change-pill {
            margin-bottom: 4px;
          }

          .standard-value {
            display: inline;
          }
        }
      }

      @media (max-width: 610px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .block-1-3 {
      flex-basis: 33%;
      max-width: 33%;
      @media (max-width: 520px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .block-2-3 {
      flex-basis: 66%;
      max-width: 66%;
    }

    .block-1-2 {
      flex-basis: 50%;
      max-width: 49%;
      @media (max-width: 610px) {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .highlighted-block {
      background-color: var(--brandBluePrimary);
      color: white;

      h4 {
        font-weight: 400;
      }
    }

    .block.icon-block {
      justify-content: flex-start;
      align-items: flex-start;

      .icon-section {
        align-self: center;

        .cup-icon {
          width: 5.5rem;
          height: 7.125rem;
        }
      }

      .value-section {
        .value-wrapper {
          .standard-value {
            display: block;
          }
        }
      }
    }
  }

    .h320 {
        height: 320px;
      }

    .h230 {
      height: 230px;
    }

    .discount-block{
      background-color: var(--gray50);
      border-radius: 12px;
      padding: 1.5rem;
      gap: 1rem;



      .current-offer-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .bottom-icons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .clickable-div {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: center;
            .icon {
              width: 16px;
              height: 16px;
            }
            .chevron-icon{
              width: 11px;
              height: 11px;
            }
            .promote-icon{
              transform: rotate(90deg);
            }
          }
        }

        .button-wrapper{
          display: flex;
          flex-direction: row;
          gap: 8px;
          margin-bottom: 16px;
        }
        .deposit-alert{
          cursor: pointer;
          display: flex;
          flex-direction: row;
          width: 100%;
          border-radius: 8px;
          border: 1px solid var(--lightred);
          align-items: center;
          padding: 12px;
          background-color: var(--white);
          p{
            font-family: Roboto, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            margin-left: 8px;
            margin-right: 8px;
          }
          .chevron-icon{
              width: 11px;
              height: 11px;
            }
        }
      }

      .title-section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
      }

      .select-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .label {
          font-size: 0.8125rem;
          font-weight: 500;
        }
        .prefix-icon-wrapper{
          position: absolute;
          z-index: 1;
          left: 12px;
          top: 12px;
          .prefix-icon{
            width: 18px;
            height: 18px;
          }
        }

        .input-select {
          width: 100%;
          border-radius: 20px;
          background-color: var(--white);
          border: 1px solid var(--brandBluePrimary);
          min-height: 40px;
          padding:5px 32px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: fit-content;
          .ant-select-selector{
            color: var(--brandBluePrimary);
          }
          .ant-select-arrow{
            color: var(--brandBluePrimary);
          }
        }

        div.ant-select.dropdown-select {
          width: 100%;
          margin-top: 0.5rem;
        }
      }

      .discount-tags-wrapper{
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        .discount-tag{
          cursor: default;
          padding: 6px 12px;
          background-color: var(--brandBluePrimary);
          color: white;
          font-size: 12px;
          line-height: 14px;
          border-radius: 16px;
          display: flex;
          flex-direction: row;
          align-items: center;
          .check-icon{
            width: 11px;
            height: 11px;
            margin-left: 8px;
          }
        }
      }

      .discount-center{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;
      }
      .discount-section-wrapper{
        display: flex;
        gap: 3rem;
        height: 100%;
        .discount-left-section{
          flex-direction: column;
          align-self: flex-start;
          gap: 1rem;
          flex-basis: 25%;
          max-width: 25%;
          .discount-select{
            width: 100%;
            height: 40px;
            border: 1px solid var(--brandBluePrimary);
            background-color: white;
            border-radius: 20px;
            padding: 4px;
            color: var(--brandBluePrimary);
            margin-bottom: 1.5rem;
            cursor: pointer;
          }
          .discount-days-left{
            font-weight: 700;
            color: var(--brandBluePrimary);
          }
          h3{
            margin-bottom: 1rem;
          }
          h4{
            margin-bottom: 1rem;
            font-weight: 400;
          }
          .button-wrapper{
            gap: 0.5rem;
          }
        }
        .discount-right-section{
          align-self: center;
          display: flex;
          flex-basis: 75%;
          max-width: 75%;
          gap: 1rem;
          .discount-media-wrapper{
            display: flex;
            flex-direction: column;
            gap: 1rem;
            max-width: 240px;
            img{
              width: 100%;
            }
          }
          h4{
            font-size: 1.25rem;
            margin-bottom: 0.25rem;
          }
          p{
            font-size: 0.875rem;
            margin-bottom: 0.25rem;
            
          }
          .note{
            color: var(--brandBluePrimary);
          }
          
          .discount-details-wrapper{
            max-width: 560px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            .discount-big-tags-wrapper{
              display: flex;
              gap: 0.5rem;
              flex-direction: column;
              .discount-big-tag{
                display: flex;
                align-items: center;
                gap: 0.5rem;
                font-size: 13px;
                justify-content: space-between;
                div{
                  display: flex;
                  align-items: center;
                  gap: 0.5rem;
                }
                img{
                  border-radius: 30px;
                  background-color: var(--gray50);
                  padding: 2px;
                  aspect-ratio: 1;
                }
                padding: 8px;
                background-color: white;
                border-radius: 8px;
                width: 100%;
              }
            }
          }
        }
      }
    }
}
.loading-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  img {
    margin-bottom: 2rem;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    width: 30%;
    margin-top: 24px;

    span {
      font-size: 18px;
      font-weight: 400;
      text-align: center;
    }

  }
}

.pie-chart-section {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .pie-chart-filling {
    transition: opacity 200ms;

    &.unchecked {
      opacity: 0.25;
      filter: grayscale(0.25);

      &.hovered {
        opacity: 0.6;
        filter: grayscale(0.1);
      }
    }

    &.selectable {
      cursor: pointer;

      &.hovered {
        opacity: 0.6;
        filter: grayscale(0.1);
      }
    }

    &.not-selectable {
      cursor: not-allowed;
    }
  }

  .pie-chart-legend {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .pie-chart-legend-row {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      align-items: center;
      font-size: 12px;
      line-height: 14px;
      min-width: 140px;

      .pie-chart-legend-label {
        color: var(--gray300);
      }

      .pie-chart-legend-pill-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;
        gap: 8px
      }

      .pie-chart-legend-pill {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 22px;
        border-radius: 4px;
        padding: 13px 4px;
      }

      .pie-chart-legend-value {
        font-weight: 500;
        color: var(--white);
        text-align: center;
      }
    }
  }
}

.chart-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;

  .no-chart {
    width: 100%;
    height: 230px;
  }

  .chart-legend {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    .chart-legend-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 0.875rem;
      color: var(--gray300);
      gap: 0.5rem;

      .dot {
        width: 1rem;
        min-width: 1rem;
        height: 1rem;
        border-radius: 0.5rem;
        display: inline-block;

        &.current {
          background-color: var(--brandBluePrimary);
        }

        &.previous {
          background-color: var(--brandGreen);
        }
      }
    }
  }

  .chart-selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;


    .chart-switch {
      background-color: var(--white);
      border-radius: 15px;
      height: 30px;
      width: 70px;
      display: flex;
      flex-direction: row;
      padding: 3px 7px;
      gap: 8px;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .image-wrapper {
        background-color: var(--gray50);
        width: 24px;
        height: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 500ms;

        &.active {
          background-color: var(--brandBluePrimary);
        }

        img.bar-chart-icon {
          width: 16px;
          height: 15px;
        }

        img.line-chart-icon {
          width: 16px;
          height: 13px;
        }
      }
    }

    .chart-interval-select {
      flex-direction: row;
      align-items: center;
    }
  }
}

.stepper-wrapper {
  display: flex;
  flex-direction: row;
  width: 600px;
  max-width: 90vw;
  background: white;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 10;
  padding-bottom: 10px;

  .stepper-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .text {
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      align-self: center;
      color: var(--gray300);
      text-wrap: nowrap;
      margin-top: 8px;

      &.activated {
        color: var(--brandBluePrimary);
      }
    }

    .info {
      width: 24px;
      height: 24px;
      margin-top: 8px;
    }
  }

  .stepper-line {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.highlight-decoration {
  position: absolute;
  z-index: 2;
  height: 200%;
  width: 200%;
  transform: rotate(235deg);
  bottom: -20%;

  .box-1 {
    background-color: #0D79FF;
    height: 100%;
    width: 100%;
  }

  .box-2 {
    background-color: #1880FF;
    height: 100%;
    width: 100%;
    position: absolute;
    bottom: -25%;
  }
}

.chart-interval-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .ant-select-selector {
    width: 145px !important;
  }

  .label-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

.controls-sticky-bar {
  position: sticky;
  top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  padding: 16px;
  border-radius: 30px;
  justify-content: space-between;
  z-index: 20;


  @media (max-width: 1120px) {
    display: none;
  }


  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .selectors-with-labels {
    align-items: center;
  }


  @media screen and (max-width: 1365px), (max-height: 705px) {
    top: 80px
  }
  @media (max-width: 1440px) {
    .target-group-button.select {
      width: 40px;
      aspect-ratio: 1;
      padding: 12px;

      p.label-small {
        display: none;
      }
    }
  }
  @media (min-width: 1366px) and (max-width: 1400px), (min-width: 900px) and (max-width: 1200px) {
    .target-group-button.create {
      width: 40px;
      aspect-ratio: 1;
      padding: 12px;

      p.label-small {
        display: none;
      }
    }
  }

}


.controls-mobile-button {
  @media (min-width: 1121px) {
    display: none;
  }
  position: fixed;
  right: 20px;
  top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  padding: 12px;
  border-radius: 24px;
  z-index: 20;
  width: min-content;
  cursor: pointer;
}

.date-picker-label-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.aggregate-select {
  .ant-select-selector {
    width: 150px !important;
  }
}

.previous-period-select {
  .ant-select-selector {
    width: 150px !important;
  }
}

.previous-period-select, .aggregate-select, .chart-interval-select, .active-client-interval-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.dashboard-content.cups {
  .previous-period-select {
    flex-direction: row;
    align-items: center;
  }
}

.dashboard-content {
  .cups-block-wrapper {
    @media (min-width: 611px) and (max-width: 1500px) {
      flex-flow: row wrap;
      .block.block-1-4,
      .block.block-1-4.icon-block {
        @media (max-width: 900px) {
         max-width: calc(50% - 0.5rem);
        flex-basis: calc(50% - 0.5rem);
        }
        max-width: calc(50% - 0.75rem);
        flex-basis: calc(50% - 0.75rem);
      }
    }

  }
}
