.loader {
  width: 120px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--brandBluePrimary);
  --_m: conic-gradient(#0000 10%, #000),
  linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: rotating 1.5s infinite linear;
}

@keyframes rotating {
  to {
    transform: rotate(1turn)
  }
}

.loader.small {
  width: 30px;
  padding: 2px;
}

.loader.returns {
  margin-left: auto;
  margin-right: auto;
}

.badge {
  width: 122px;
  height: 122px;
  border-radius: 100px;
  position: relative;

  &.badge-success {
    background-color: var(--green);

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 50px;
      border-bottom: 3px solid var(--white);
      border-right: 3px solid var(--white);
      transform: rotate(45deg);
      left: 46px;
      top: 29px;
    }
  }

  &.badge-error {
    background-color: var(--red);

    &:before,
    &:after {
      content: "";
      position: absolute;
      display: block;
      background: var(--white);
      width: 50px;
      height: 3px;
      transform: rotate(45deg);
      left: 36px;
      top: 59px;
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}


.list-bars {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    cursor: pointer;
    gap: 10px;
    height: 3rem;
    padding: 0.75rem 1rem;
    align-items: center;
    border-radius: 8px;
    background: var(--gray50);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .img-wrapper {
    width: 24px;
    height: 24px;
    border-radius: 10px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .returns {
    display: contents;
  }

  img {
    max-width: 18px;
    max-height: 18px;
  }

  .link-arrow {
    margin-left: auto;
    margin-right: 10px;
  }
}

.pagination {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
  display: flex;
  justify-content: flex-end;
  gap: 5px;

  li {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.25rem;
    text-align: center;
    background: var(--white);
    color: var(--gray300);
    line-height: 1.5rem;
    cursor: pointer;

    &:hover {
      color: black;
    }

    &.active {
      background: var(--brandBluePrimary);
      color: var(--white);
    }
  }
}

.badge-attention {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: var(--red);
  color: var(--white);
  border-radius: 50%;
  font-size: 14px;
  font-weight: 700;

  &.small {
    width: 18px;
    height: 18px;
    font-size: 12px;
  }

  &.red {
    background-color: var(--red);
  }

  &.green {
    background-color: var(--green);
  }

}