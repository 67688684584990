.modal-content.contact {
  min-height: 700px;
  width: 700px;

  .modal-header {
    text-align: left;
    margin-bottom: 1.5rem;
    align-items: flex-start;

    &.menu-header {
      text-align: center;
      margin-bottom: 2rem;
    }

    .modal-title {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;

      .back-button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 0.5;
        }

        img {
          width: 28px;
          height: 28px;
        }
      }


    }

    h5 {
      margin-bottom: 0;
    }
  }
}


.chat-list, .new-chat {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .searchbar {
    height: 40px;
    background-color: var(--gray50);
    color: var(--gray200);
    max-width: unset;
    border: none;

    .searchbar-input {
      background-color: var(--gray50);
      color: var(--black)
    }
  }

  .chats {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: scroll;

    .user-profile-icon {
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 24px;
      }
    }

    .chat-overview,
    .user-overview {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      padding: 12px 16px;
      background-color: var(--gray50);
      border-radius: 8px;

      &:hover {
        opacity: 0.5;
      }

      .user-name {
        font-size: 14px;
      }

    }
  }
}


.chat-list .chats {
  height: 400px;

  .chat-overview {
    justify-content: space-between;

    div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      div:nth-of-type(2) {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 250px;

        .message-content {
          font-size: 12px;
          text-wrap: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &.unread {
            font-weight: bold;
          }
        }
      }
    }

    .timestamp {
      font-size: 12px;

      &.unread {
        font-weight: bold;
      }
    }
  }
}

.new-chat .chats {
  max-height: 520px;

  .user-overview {
    gap: 0.5rem;
  }
}

.user-message .admin-message {
    overflow-y: auto;
    max-height: 600px;
}


.admin-message {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  textarea.form-control {
    min-height: 200px;
    max-height: 250px;
  }
  .button {
    margin-top: 1rem;
  }

  div.ant-select.dropdown-select.merchant {
    margin-top: 8px;
    width: 100%;
  }
}


.chat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .chat-window {
    height: 490px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
    position: relative;

    .user-message, .merchant-message {
      display: flex;
      flex-direction: column;

      .message-container {
        padding: 16px;
        border-radius: 20px;

        .message-content {
          font-size: 12px;
          line-height: 18px;
        }

      }

      .timestamp {
        font-size: 12px;
        color: var(--gray250);
        margin-top: 4px;
      }
    }

    .merchant-message {
      align-items: flex-end;

      .message-container {
        background-color: var(--brandBluePrimary);
        border-bottom-right-radius: 0;

        .message-content {
          color: var(--white);
          text-align: right;
        }
      }
    }

    .user-message {
      align-items: flex-start;

      .user-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .user-profile-icon {
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background: var(--gray50);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-height: 15px;
            max-width: 15px;
          }
        }

        .user-name {
          font-size: 14px;
        }
      }

      .message-container {
        background-color: var(--gray50);
        border-top-left-radius: 0;
        margin-left: 40px;
      }

      .timestamp {
        margin-left: 40px;
      }

    }

    .load-more {
      align-self: center;
      background-color: var(--brandBluePrimary);
      min-width: 28px;
      min-height: 28px;
      border-radius: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      .chevron::before {
        border-color: var(--white);
      }
    }

    .messages-loading {
      position: absolute;
      top: 10px;
      left: 50%;
      width: 28px;
      height: 28px;
    }

    .messages-loading::after {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      top: 0;
      right: 14px;
      bottom: 14px;
      margin: auto;
      border: 4px solid var(--gray100);
      border-radius: 50%;
      animation: messages-loading-spinner 1s ease infinite;
    }

    .messages-loading::after {
      border-top-color: var(--brandBluePrimary);;
    }

    @keyframes messages-loading-spinner {
      from {
        transform: rotate(0turn);
      }

      to {
        transform: rotate(1turn);
      }
    }
  }

  .chat-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
    width: 100%;
    min-height: 40px;

    .message-input {
      width: 100%;
      background-color: var(--gray50);

      padding: 10px 16px;
      border-radius: 8px;

      textarea {
        resize: none;
        border: none;
        background-color: var(--gray50);
        width: 100%;
        overflow: hidden;
        padding: 0;
        line-height: 20px;
        font-size: 12px;

        &::placeholder {
          color: var(--gray200);
        }
      }

    }

    .send-button {
      min-width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--brandBluePrimary);
      border-radius: 8px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &:hover {
        opacity: 0.5;
      }

      img {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--brandBluePrimary);
      }
    }
  }

}