.divider{
  margin: 8px 0;
}
.space{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 8px 4px;
  .space-input{
    width: 120%;
  }
}

.stepper-modal-content {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 700px;
  max-width: 90vw;
  min-height: 500px;
  background: white;

  .modal-header {
    padding: 2.5rem 2.5rem 0;
    display: flex;
    justify-content: space-between;
    .title-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
    }
  }

  .header-content {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }

  .m0 {
    margin: 0;
  }

  .mb8{
    margin-bottom: 8px;
  }

  h5 {
    margin-top: 0;
    font-size: 1.5rem;
    line-height: 28px;
  }

  .modal-close {
    cursor: pointer;
    width: 28px;
    height: 28px;

    &:hover {
      opacity: 0.5;
    }

    img {
      width: 28px;
      height: 28px;
    }
  }

  .video-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

  .label {
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }

  .text-gray {
    color: var(--gray250);
  }

  .required-mark {
    color: var(--brandBluePrimary);
  }

  .pointer{
    cursor: pointer;
  }

  .w700 {
    font-weight: 700;
  }

  .tag {
    background-color: var(--brandBluePrimary);
    color: var(--white);
    border-radius: 11px;
    padding: 3px 12px;
    margin: 2px;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: fit-content;
  }

  .modal-body {
    flex-grow: 2;
    padding: 0 2.5rem 1rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;

    .add-image {
          margin-bottom: 31px;
          margin-top: 31px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          height: 164px;

          p {
            font-weight: 400;
            font-size: 11px;
            color: var(--gray300);
            font-family: Roboto, sans-serif;
          }
        }

    .input-image {
        background-size: cover;
        background-position: center;
        border-radius: 12px;
        background-color: var(--gray50)
      }

      .input-video {
        width: 416px;
        height: 234px;
        max-width: 100%;
        border-radius: 12px;
        background-color: var(--gray50);
        display: flex;
        border: 2px dashed var(--gray200);
        justify-content: center;
        align-items: center;
        text-align: center;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        color: var(--gray300);
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 0.6875rem;
        transition: 200ms;
      }

      .input-video:hover {
        background-color: var(--gray100);
      }

      .selected-video {
        border: 2px solid blue;
      }

      .input-image.offer-image::before {
        content: 'Dodaj obrazek';
        height: 164px;
        width: 393px;
        max-width: 100%;
      }

      .input-image.offer-image {
        display: block;
        height: 164px;
        width: 393px;
        max-width: 100%;
      }

      .input-image.selected::before {
        border: 2px solid blue;
        //content: "";
      }

      .input-image.selectable {
        &:hover::before {
          opacity: 0.6;
        }

        &::before {
          content: "";
        }
      }

      .input-image::-webkit-file-upload-button {
        visibility: hidden;
      }

      .input-image::before,
      .category-pick {
        display: flex;
        border: 2px dashed var(--gray200);
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 12px;
        outline: none;
        white-space: nowrap;
        -webkit-user-select: none;
        color: var(--gray300);
        cursor: pointer;
        text-shadow: 1px 1px #fff;
        font-weight: 700;
        font-size: 0.6875rem;
        transition: 200ms;
      }

      .category-pick {
        background-color: var(--gray50);
      }

      .input-image:hover::before,
      .category-pick:hover {
        background-color: var(--gray100);
      }

    .file-input-inner-wrapper {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .note {
            margin: 0;
            width: 100%;
            color: var(--gray300);
            max-width: 393px;
          }

          .sub-label {
            font-size: 0.6875rem;
          }

          .category-pick {
            width: 75px;
            aspect-ratio: 1;
            position: relative;
            font-size: 15px;
            font-weight: 400;
          }

          .category-pick::before {
            position: absolute;
            content: "";
            width: 24px;
            aspect-ratio: 1;
            border-radius: 20px;
            border: 1px solid var(--gray300);
          }

          .service-tile:hover {
            opacity: 0.6;
          }

          .remove-wrapper {
            position: relative;
            width: min-content;
            max-width: 100%;

            input {
              margin-top: 0;
            }

            .remove {
              position: absolute;
              top: -0.5rem;
              right: -0.5rem;

              img {
                width: 1rem;
                height: 1rem;
              }

              &:hover {
                cursor: pointer;
                opacity: 0.6;
              }
            }
          }
        }

    .mb8 {
      margin-bottom: 8px;
    }

    .mr8 {
      margin-right: 8px;
    }

    .mb12 {
      margin-bottom: 12px;
    }

    .mb16 {
      margin-bottom: 16px;
    }

    .mb24 {
      margin-bottom: 24px;
    }

    .ml26 {
      margin-left: 26px;
    }

    .w700 {
      font-weight: 700;
    }

    .jc-center {
      justify-content: center;
    }

    .one-line {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
      }

    .input {
          width: 100%;
          border-radius: 20px;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: fit-content;
        }

    .text-gray {
          color: var(--gray300);
        }
    .input-text {
      height: auto;
      resize: none;
      background-color: var(--gray50);
      border: 0;
      width: 100%;
      overflow: hidden;
    }

    .desc-switch-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    .img-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 24px;

      .img {
        width: 494px;
        height: 494px;
        position: relative;

        .top-decoration {
          position: absolute;
          top: 0;
          right: 0;
        }
        .bottom-decoration {
          position: absolute;
          bottom: 0;
          left: 0;
        }

        .left {
          align-items: flex-start;
          text-align: left;
        }

        .center{
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .big-content{
          top: 43px;
          height: 338px;
        }
        .medium-content{
          top: 69px;
          height: 286px;
        }
        .small-content{
          top: 102px;
          height: 220px;
        }

        .content-wrapper {
          position: absolute;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width:420px;
          left: 40px;
        }
        .logo {
          width: 92px;
          height: 92px;
          background-size: 92px 92px;
          border-radius: 12px;
          background-position: center;
          margin-bottom: 12px;
        }
        .black {
          color: var(--black);
        }
        .white {
          color: var(--white);
        }

        .offer-title-wrapper {
          width: 420px;

          .offer-title {
            font-family: Roboto, sans-serif;
            font-weight: 700;
            margin-bottom: 6px;
            word-break: break-word;
          }
          .big-title{
            font-size: 44px;
            line-height: 48px;
          }
          .medium-title{
            font-size: 38px;
            line-height: 44px;
          }
          .small-title{
            font-size: 30px;
            line-height: 35px;
          }
        }

        .big-title-wrapper{
          height: 153px;
        }
        .medium-title-wrapper{
          height: 132px;
        }
        .small-title-wrapper{
           height: 70px;
        }

        .offer-desc-wrapper {
          width: 420px;

          .offer-desc {
            font-family: Roboto, sans-serif;
            font-weight: 500;
            word-break: break-word;
          }
          .big-desc {
            font-size: 18px;
            line-height: 25px;
          }
          .medium-desc {
            font-size: 16px;
            line-height: 22px;
          }
          .small-desc {
            font-size: 14px;
            line-height: 20px;
          }
        }

        .big-desc-wrapper {
          height: 75px;
        }
        .medium-desc-wrapper {
          height: 44px;
        }
        .small-desc-wrapper {
          height: 40px;
        }

        .img-preview {
          width: 494px;
          height: 494px;
          object-fit: cover;
        }
    .color-1 {
      background: var(--gray250) linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-2 {
      background: var(--brandBluePrimary) linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-3 {
      background: var(--brandBlueSecondary) linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-4 {
      background: #F8D57A linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-5 {
      background: #FFB673 linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-6 {
      background: #A4DF76 linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-7 {
      background: #60D472 linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-8 {
      background: #A779CC linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-9 {
      background: #E593D8 linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }

    .color-10 {
      background: #E98F9F linear-gradient(311deg, rgba(255, 255, 255, 0) 6.07%, rgba(255, 255, 255, 0.3) 100%);
    }
      }

    }

    .theme-wrapper {
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;
      .add-icon-wrapper {
        width: 24px;
        height: 24px;
        margin-top: 8px;
        margin-right: 8px;
        .add-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
      .color-wrapper{
        width: 312px;
      }
      .add-color-icon {
        width: 24px;
        height: 24px;
        margin-top: 8px;
        margin-left: 8px;
        cursor: pointer;
      }
    }

    .text-theme-wrapper{
      display: flex;
      flex-direction: row;
      gap: 40px;
      width: 100%;
      .text-color-wrapper{
        display: flex;
        flex-direction: row;
        .color-picker-icon{
          width: 24px;
          height: 24px;
          margin-top: 8px;
          margin-left: 8px;
          cursor: pointer;
        }
      }
      .text-size-wrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        .big-icon {
          width: 30px;
          height: 20px;
          cursor: pointer;
        }
        .medium-icon {
          width: 21px;
          height: 14px;
          cursor: pointer;
        }
        .small-icon {
          width: 15px;
          height: 10px;
          cursor: pointer;
        }
      }
      .text-format-wrapper {
        display: flex;
        flex-direction: row;
        gap: 8px;
        .icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

     .color-pick-wrapper {
      margin-top: 0.5rem;
      display: flex;
      gap: 0.5rem;
      width: 100%;
      flex-wrap: wrap;

    .color-pick {
      border-radius: 40px;
      width: 24px;
      height: 24px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: opacity 200ms;

      &.selected {
        border: 1px solid var(--brandBluePrimary);
      }

      &:hover {
        opacity: 0.6;
      }
    }

    .color-1 {
      background-color: var(--gray250);
    }

    .color-2 {
      background-color: var(--brandBluePrimary);
    }

    .color-3 {
      background-color: var(--brandBlueSecondary);
    }

    .color-4 {
      background-color: #F8D57A;
    }

    .color-5 {
      background-color: #FFB673;
    }

    .color-6 {
      background-color: #A4DF76;
    }

    .color-7 {
      background-color: #60D472;
    }

    .color-8 {
      background-color: #A779CC;
    }

    .color-9 {
      background-color: #E593D8;
    }

    .color-10 {
      background-color: #E98F9F;
    }

    .color-pick::before {
      content: "";
      width: 50px;
      height: 20px;
      background-color: white;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 2px;
      transform: rotate(45deg);
    }
  }
   .theme-color-picker {
    .color-pick-wrapper {
      .color-1 {
        background-color: var(--black);
        border: 1px solid var(--gray250);
        &.selected {
          border: 1px solid var(--brandBluePrimary);
        }
      }
      .color-2 {
        background-color: var(--brandBluePrimary);
      }
      .color-pick::before {
        background-color: var(--gray100);
      }
    }
  }
  .text-color-picker {
    .color-pick-wrapper {
      .color-1 {
        background-color: var(--black);
        border: 1px solid var(--gray250);
        &.selected {
          border: 1px solid var(--brandBluePrimary);
        }
      }
      .color-2 {
        background-color: var(--gray50);
      }
      .color-pick::before {
        background-color: var(--gray100);
      }
    }
  }


    .step-form-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .step-form {
        width: 70%;
      }

      .ant-select-selection-placeholder {
        font-size: 13px;
        color: var(--gray300);
      }

      .ant-select-selection-search-input {
        font-size: 13px;
        color: var(--gray300);
      }
      .ant-select-selector{
        font-size: 13px;
      }

      .preview-h2 {
        font-family: Roboto, sans-serif;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--black);
        margin-top: 8px;
        margin-bottom: 4px;
      }

      .preview-h3 {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--black);
        margin-bottom: 8px;
        margin-top: 0;
        width: 100%;
      }

      .preview-p {
        font-family: Roboto, sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black);
        opacity: 0.8;
      }

      .w700{
        font-weight: 700;
      }

      .preview-span {
        font-family: Roboto, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }

      .ellipsis-text {
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .wrap {
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
        max-width: 100%;
      }

      .primary {
        color: var(--brandBluePrimary);
      }

      .preview-content-wrapper{
        width: 350px;
      }

      .days-offer-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .tags-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 32px;
      }

      .media {
        width: 348px;
        height: 196px;
        border-radius: 12px;
        object-fit: cover;
        aspect-ratio: 16/9;
      }
      .media-horizontal {
        width: 348px;
        height: 196px;
        border-radius: 12px;
        object-fit: cover;
        aspect-ratio: 16/9;
      }
      .media-vertical {
        height: 348px;
        width: 196px;
        border-radius: 12px;
        object-fit: cover;
        aspect-ratio: 9/16;
      }

      .section {
        margin-bottom: 16px;
        &.validation{
          margin-top: 8px;
        }
        div.ant-select.dropdown-select {
          margin-top: 0;
        }

        .label-margin {
          margin-bottom: 8px;
        }

        .label-space-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .preview-wrapper {
          position: relative;
          cursor: pointer;
          margin-bottom: 8px;
        }

        .details-wrapper {
          display: flex;
          flex-direction: column;
          padding: 16px;
          border-radius: 20px;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          position: relative;
          justify-content: center;
        }

        .cashback-size-select {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          margin: 0.5rem 0;
          @media screen and (max-width: 1060px) {
            justify-content: flex-start;
            gap: 1rem;
          }

          .cashback-size-pill {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 0;
            width: 120px;
            height: 36px;
            border-radius: 49px;
            border: 1px solid var(--gray100);
            opacity: 0.6;

            img {
              margin-right: 1rem;
            }

            &.low {
              color: var(--navy)
            }

            &.medium {
              color: var(--brandBluePrimary)
            }

            &.high {
              color: var(--green)
            }

            &.selected {
              border: none;
              box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
              opacity: unset;
              transition: opacity 400ms;
            }
          }
        }

        .slider-value-container {
          .label {
            color: var(--gray200);
          }

          .slider-input-wrapper {
            background-color: white;
            border: solid 1px var(--gray300);
            border-radius: 1rem;
            width: 56px;
            height: 1.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 8px 0;

            .label {
              color: var(--brandBluePrimary);
            }

            .input-wrapper {
              height: 0.8125rem;
              display: flex;
              width: 2.125rem;

              input {
                height: 0.8125rem;
                background-color: white;
                margin-top: 0;
                text-align: center;
                padding: 0;
                font-weight: 500;
                font-size: 0.8125rem;
                color: var(--brandBluePrimary);
              }
            }

          }
        }

        .w400 {
          font-weight: 400;
        }



        .radio-wrapper {
          display: flex;
          flex-direction: row;
          margin-top: 5px;
          gap: 16px;
        }

        .input {
          border-radius: 20px;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: row;

          .ant-select-arrow{
            color: var(--black);
          }
        }

        .input-description {
          border-radius: 20px;
          background-color: var(--gray50);
          border: 0;
          padding: 5px 10px;
          position: relative;
        }

        .localization-input {
          width: 100%;
          border-radius: 30px;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          position: relative;
          display: flex;
          justify-content: center;
          flex-direction: row;
        }

        .text-gray {
          color: var(--gray300);
        }

        .input-text {
          background-color: var(--gray50);
          border: 0;
          width: 100%;
          font-size: 13px;
          font-family: 'Roboto', sans-serif;
        }

        .pin {
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }

        .preview-pin {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }

        .input-place {
          width: 75%;
          border: 0;
          background-color: var(--gray50);
          min-height: 40px;
          padding: 5px 15px;
          border-right: 1px solid #CCCCCC;
        }

        .localization-input-place {
          width: 75%;
          border: 0;
          background-color: var(--gray50);
          min-height: 40px;
          padding: 5px 15px;
          border-right: 1px solid #CCCCCC;
          margin-left: 20px;
          color: var(--gray300);
          font-size: 13px;
          line-height: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
        }

        .localization-input-place-active{
          color: var(--black);
          width: 75%;
          border: 0;
          background-color: var(--gray50);
          min-height: 40px;
          padding: 5px 15px;
          border-right: 1px solid #CCCCCC;
          margin-left: 20px;
          font-size: 13px;
          line-height: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
        }

        .input-radius {
          width: 20%;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          color: var(--gray300);
          font-size: 13px;
          line-height: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
        }

        .input-radius-active {
          width: 20%;
          background-color: var(--gray50);
          border: 0;
          min-height: 40px;
          padding: 5px 10px;
          color: var(--black);
          font-size: 13px;
          line-height: 15px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
        }

        .wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .text-grey {
            color: var(--gray250);
          }

        .offer-wrapper {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-bottom: 8px;
          align-items: center;
        }



        .icon-wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            .pen-icon{
              cursor: pointer;
              margin-right: 8px;
              width: 16px;
              height: 16px;
            }
            .info-icon {
              margin-left: 2px;
              width:24px;
              height:24px;
            }
        }

        .with-border {
          border-bottom: 1px solid var(--gray100);
          margin-bottom: 8px;
        }

        h6 {
          font-size: 14px;
          font-weight: 600;
          color: var(--black);
          margin-bottom: 8px;
        }

        .header {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          margin-bottom: 8px;

          span {
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            color: var(--brandBluePrimary);
            transition: color 0.2s;

            &:hover {
              color: var(--brandBlueSecondary);
            }
          }
        }
      }
    }

    .stepper-span {
      font-size: 10px;
      font-weight: 400;
    }

    .blue {
      color: var(--brandBluePrimary);
    }
    .red {
      color: var(--lightred);
    }
  }
  .img-gen-footer{
    padding: 0 24px 32px;
    .button {
      margin: 8px 0 0 0;
    }
  }
  .modal-footer {
    padding-bottom: 32px;
    display: flex;
    gap: 16px;
    .button {
      margin: 8px 0 0 0;
    }
  }
}
.stepper-radio-wrapper{
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.stepper-radio-inputs {
  display: flex;
  align-items: center;
  flex-direction: row;

  input {
    border: 1px solid #989898;
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-left: 0;
    margin-right: 8px;
  }

  p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.success-modal-content {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);
  border-radius: 12px;
  width: 580px;
  max-width: 90vw;
  min-height: 500px;
  background: white;
}