.statistics-details-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}


.active-client-interval-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .ant-select-selector {
    width: 130px !important;
  }
}



.statistics-chart-block-wrapper {
  @media (max-width: 800px) {
    flex-direction: column
  }

  .block.chart-block-vertical {
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: 801px) and (max-width: 1199px) {
      justify-content: unset;
    }


    &.active-clients {
      gap: 0;
      .active-client-interval-select {
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
      }
    }

    @media (max-width: 800px) {
      flex-basis: 100%;
      max-width: 100%;
    }

    .pie-chart-section {
      @media (min-width: 801px) and (max-width: 1199px) {
        flex-direction: column;
        gap: 16px;
      }

      .pie-chart-legend {
        @media (min-width: 801px) and (max-width: 1199px) {
          width: 100%;
        }
      }
    }

    .title-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;

      .actions-button {
        margin-right: -1rem;
      }

      .title {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;

        span {
          text-wrap: nowrap;
          font-size: 18px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          @media (max-width: 1600px) {
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 250px;
          }
          @media (max-width: 1100px) {
            max-width: 200px;
          }
          @media (max-width: 900px) {
            max-width: 175px;
          }
          @media (max-width: 825px) {
            max-width: 150px;
          }
        }
      }
    }

    .no-chart {
      height: 140px;
    }
  }
}

.block-wrapper.block-wrapper-statistics {
  @media (max-width: 610px) {
    flex-direction: column;
  }
}


.range-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .label {
    font-size: 0.8125rem;
    font-weight: 500;
  }

  .date-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .label.blue {
      color: var(--brandBluePrimary);
    }
  }

  .range-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;

    .input-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .input-wrapper {
        width: 100%;
        input {
          margin-top: 0
        }
      }

      .switch-wrapper {
        margin-left: 8px;
      }
    }

    .range-select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      div.ant-select.dropdown-select {
        width: 95%;
      }

      img {
        cursor: pointer;
      }
    }
  }

  &.amount {
    .amount-range-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .amount-range-radio-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        input {
          margin: 0;
        }

        .radio-label {
          font-size: 11px;
          line-height: 13px;
        }
      }
    }
  }
}


.target-group-floating-buttons {
  position: fixed;
  bottom: 42px;
  align-self: end;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 3;

  .target-group-changes-counter {
    background-color: var(--brandBluePrimary);
    height: 40px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    gap: 8px;
    padding: 0 16px;

    .label {
      color: var(--white);
      font-size: 16;
    }

    .remove {
      cursor: pointer;
    }
  }

  .target-group-management {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    height: 40px;
    border-radius: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    padding: 0 16px;
    font-size: 16;
    color: var(--brandBluePrimary);
    border: 1px solid var(--brandBluePrimary);
  }

  .target-group-selected-filters {
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3)
    }
  }
}


.dashboard-content.statistics.with-sticky-bar {
  .controls-sticky-bar {
    @media (max-width: 1120px) {
      display: none;
    }
  }
  .controls-mobile-button {
    @media (min-width: 1121px) {
      display: none;
    }
  }
}