
.block-wrapper .block-1-1.block.no-bookings {
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 3;

  > img {
    width: 40%;
  }

  .button {
    max-width: 160px;
  }
}

.go-back {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.tab-manager {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .tab-pill {
    border: 1px solid var(--gray200);
    color: var(--gray200);
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 1rem;
    display: flex;

    > span {
      font-size: 12px;
      line-height: 14px;
      color: var(--gray200);
    }

    &:hover {
      opacity: 0.5;
    }

    &.selected {
      border-color: var(--brandBluePrimary);
      background: var(--brandBluePrimary);
      cursor: default;

      &:hover {
        opacity: unset;
      }

      > span {
        color: var(--white);
      }
    }
  }
}

.booking-edit {

  .header-wrapper {
    margin-bottom: -6px;
  }

  .controls-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .filters-row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .searchbar, .filters-button {
        height: 40px;
      }
    }

    .buttons-row {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;

      .button {
        span {
          text-wrap: nowrap;
        }

        img {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    @media (max-width: 780px) {
      flex-direction: column;
      gap: 0.5rem;
    }
    @media (max-width: 500px) {
      .buttons-row {
        .button.more-options {
          span {
            display: none;
          }
        }
        .button {
          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .categories {
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    //align-items: start;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 2rem;
    align-items: start;

    @media (max-width: 900px) {
      grid-template-columns: repeat(2, 2fr);
    }
  @media (max-width: 600px) {
      grid-template-columns: repeat(1, 2fr);
    }


    .category {
      //flex-basis: calc(33.33% - 1rem);
      //margin-bottom: 2rem;
      display: flex;
      flex-direction: column;


      .category-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        > div {
          display: flex;
          align-items: center;

          .category-title {
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            max-width: 200px;
            text-wrap: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-wrap: nowrap;

            &:hover {
              opacity: 0.5;
            }
          }

          .chevron {
            cursor: pointer;
            padding: 6px 4px 6px 0;
            margin-right: 4px;
            margin-left: 2px;
          }
        }

        .add-item {
          cursor: pointer;

          &:hover {
            opacity: 0.5;
          }
        }
      }

      ul.items-list {
        list-style-type: none;
        padding: 0;
        margin: 0;
        transition: max-height 0.3s ease, opacity 0.3s ease;
        max-height: 1000px; // Set a high value to ensure it's visible by default
        opacity: 1;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        overflow: hidden;

        &.hidden {
          max-height: 0;
          opacity: 0;
        }

        li.item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.5rem;
          background-color: var(--gray50);
          border-radius: 12px;
          cursor: pointer;
          border: 1px solid var(--gray50);

          &:hover {
            opacity: 0.5;
          }

          &.not-active {
            opacity: 0.5;

            &:hover {
              border: 1px solid var(--brandBluePrimary);
            }
          }

          &.empty-stock {
            border: 1px solid var(--red);
          }

          div:first-of-type {
            display: flex;
            align-items: flex-start;
            flex-direction: column;

            .item-name {
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 2px;
            }

            .item-description {
              font-size: 12px;
              color: var(--gray300);
            }

            .item-no-description {
              font-size: 12px;
              color: var(--brandBluePrimary);
              font-weight: 500;
            }

            .item-details {
              display: flex;
              align-items: center;
              flex-direction: row;
              color: var(--black);
              gap: 4px;
              font-size: 12px;
              line-height: 14px;
              margin-top: 4px;

              .stock-quantity {
                color: var(--brandBluePrimary);
              }

              img {
                width: 14px;
                height: 14px;
              }

              .stock-quantity.empty {
                color: var(--red);
              }
            }

          }

          div:last-of-type {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            .item-price {
              font-weight: bold;
              font-size: 12px;
            }

            .chevron.right {
              cursor: pointer;

              &::before {
                border-color: var(--gray300);
                width: 8px;
                height: 8px;
                border-width: 2px 2px 0 0;
                top: -2px
              }
            }
          }
        }

      }

    }
  }
}


.category-modal {
  min-height: 300px;

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 2rem
  }
}

.category-item-modal {
  .modal-header {
    padding: 2rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 3px;

    div:first-of-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      h5.modal-title {
        margin: 0;
      }
    }
  ;

    .status-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      flex-direction: row;
      gap: 2px;

      .status {
        font-weight: 600;

        &.empty-stock {
          color: var(--red);
        }

        &.not-active {
          color: var(--gray300);
        }

        &.active {
          color: var(--brandBluePrimary);
        }
      }
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;

    .double-input-wrapper {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      .input-wrapper {
        flex-basis: 50%;
      }

      .select-wrapper {
        flex-basis: 50%;

        .label {
          line-height: 1.5rem;
        }
      }
    }

    .date-picker-range-input {
      margin-top: 0.5rem;
      width: 100%;
    }
  }

  .modal-footer {
    flex-direction: column;
    gap: 8px;
    padding: 2rem;

    > div {
      display: flex;
      flex-direction: row;
      gap: 8px
    }
  }
}


.notification-popup.import-errors {
  max-width: 500px;
  min-width: 300px;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .close {
      cursor: pointer;
      width: 28px;
      height: 28px;

      &:hover {
        opacity: 0.5;
      }

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .errors {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 2px;

    > span {
      font-size: 12px;
      display: flex;
      flex-direction: row;
    }
  }
}

.employee-schedule-modal {
  .modal-header h5 {
    margin-bottom: 1rem;
  }

  .tab-manager {
    margin-bottom: 1rem;
  }
}


.calendar-with-slots {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: transparent;

  .calendar {
    background-color: transparent;

    .calendar-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      margin-bottom: 1rem;

      .title {
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }

      .month-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }

        img {
          width: 20px;
          height: 20px;
        }

        &:nth-of-type(2) {
          transform: rotate(180deg);
        }
      }
    }

    .ant-picker-panel {
      border: none;
      background-color: transparent;

      .ant-picker-date-panel .ant-picker-body {
        padding: 0;

        .ant-picker-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          height: unset;
          background-color: transparent;

          thead > tr {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1.5rem;
            background-color: transparent;

            > th {
              font-size: 12px;
              line-height: 14px;
              font-family: 'Roboto', Arial, sans-serif;
              color: var(--gray300);
            }
          }

          tbody {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            > tr {
              display: flex;
              flex-direction: row;
              gap: 1.5rem;
              background-color: transparent;

              td.ant-picker-cell {
                font-size: 14px;
                line-height: 14px;
                padding: 0;
                height: 24px !important;
                width: 24px !important;

                &.ant-picker-cell-disabled::before {
                  background-color: transparent;
                }

                &.ant-picker-cell-disabled .ant-picker-cell-inner {
                  background: var(--gray100);
                }

                &:not(.ant-picker-cell-selected) {
                  .ant-picker-cell-inner.cell-edited .ant-picker-calendar-date-value {
                    color: var(--brandBluePrimary);
                    font-weight: bold;
                  }
                }

                .ant-picker-cell-inner {

                  .ant-picker-calendar-date-content {
                    position: absolute;
                    right: -12px;
                    bottom: 10px;

                    .calendar-day-badge {
                      color: var(--brandBluePrimary);
                      font-size: 10px;
                      font-weight: 500;

                      &::before {
                        position: absolute;
                        bottom: 1px;
                        content: '';
                        border-radius: 3px;
                        border: 3px solid var(--red);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .slots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 0.5rem;

    .slot {
      width: 70px;
      padding: 11px 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      border: 1px solid var(--brandBluePrimary);

      &:hover {
        opacity: 0.6;
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
      }

      > span {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
      }

      &.available {
        color: var(--brandBluePrimary);
      }

      &.time_off {
        border-color: var(--gray200);
        color: var(--gray200);
      }

      &.reserved {
        color: var(--white);
        background-color: var(--brandBluePrimary);
      }

      &.selected {
        opacity: 0.5
      }
    }
  }
}

.calendar-with-slots,
.booking-details-modal {
  .horizontal-divider {
    height: 1px;
    width: 100%;
    background-color: var(--gray100);
  }
}

.booking-details {
  .booking-date {
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0.5rem;
  }

  .booking-status {
    font-size: 16px;
    line-height: 19px;
    color: var(--brandBluePrimary);
    margin-bottom: 2px;

    > span {
      font-weight: bold;

      &.completed {
        color: var(--gray200);
      }

      &.canceled {
        color: var(--red);
      }
    }
  }

  .booking-note {
    font-size: 14px;
    line-height: 16px;
    color: var(--gray300);
  }

  .booking-summary-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    > div:first-of-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;

      .logo {
        width: 42px;
        height: 42px;
        border-radius: 0.5rem;
        border: 1px solid var(--gray300);
        display: flex;
        justify-content: center;
        align-items: center;

        > img {
          border-radius: 0.5rem;

        }
      }

      > div:last-of-type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 16px;
        line-height: 19px;

        > span:last-of-type {
          font-weight: bold;
          color: var(--brandBluePrimary);
        }
      }
    }

    > div:last-of-type {
      padding: 5px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > span:first-of-type {
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
      }

      > span:last-of-type {
        font-size: 12px;
        line-height: 14px;
        color: var(--gray300);
      }
    }
  }

  .cashback-row,
  .amount-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 16px;
  }

  .cashback-row {
    color: var(--brandBluePrimary);
    margin-top: 1.5rem;

    > img {
      height: 15px;
    }
  }

  .amount-row {
    font-weight: bold;
    margin-top: 1rem;
  }

  .buttons {
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2rem;

    .button {
      max-width: 200px;
    }
  }

  .employee {
    background-color: var(--gray50);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    width: fit-content;

    > div {
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 12px;

      img {
        height: 14px;
      }
    }

    .employee-name {
      font-size: 16px;
      line-height: 19px;
      color: var(--brandBluePrimary);

      > span {
        color: var(--gray300);
      }
    }
  }

  .order-products {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 300px;
    overflow-y: scroll;

    .order-product-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        .order-product-quantity {
          font-size: 12px;
          line-height: 14px;
          font-weight: 500;
          height: 20px;
          background-color: var(--gray50);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 5px 0;
        }

      }

      .order-product-name,
      .order-product-price {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}


.booking-details-modal {
  .modal-header .modal-title {
    margin-bottom: 2px;
  }
}


.reservation-calendar-view {
  .table-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 0;

    .calendar-view {
      padding: 24px;

      .tab-manager {
        margin-bottom: 1rem;
      }

      .loading-screen {
        max-height: 400px;
      }

      flex-basis: 50%;
    }

    .no-reservations,
    .reservation-details-view,
    > .loading-screen {
      flex-basis: 50%;
      background-color: white;
      box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
      border-bottom-right-radius: 12px;
    }

    > .loading-screen {
      height: unset;
    }

    .no-reservations {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      > img {
        width: 150px;
        height: 150px;
      }

      > p {
        color: var(--gray200);
        font-size: 18px;
        line-height: 21px;
        font-weight: 500;
      }
    }

    .reservation-details-view {
      padding: 24px;

      h3 {
        margin-bottom: 2px;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      .calendar-view,
      .reservation-details-view {
        padding: 1rem;
      }
      .calendar-with-slots .slots {
        flex-wrap: nowrap;
        overflow-y: scroll;
      }
    }
  }
}

.affected-reservations-modal {
  .tab-manager {
    margin-bottom: 2rem;
  }

  .table-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }

  table {
    width: 100%;

    font-size: 14px;
    line-height: 16px;
    border-spacing: 0px;
    border-collapse: separate;
    border-spacing: 0 0.5rem;

    thead > tr > th {
      font-weight: normal;
      text-wrap: nowrap;
      padding: 0 0 0 1rem;
      text-align: left;
    }
  }

  .date-cell {
    text-align: right;
    padding: 0 1rem 0 0;

    &.previous {
      color: var(--gray300);
    }
  }


  tbody > tr {
    background-color: var(--gray50);
    height: 40px;
    border-radius: 12px;

    > td {
      padding: 0 0 0 1rem;
      text-wrap: nowrap;
      text-align: left;

      &:first-of-type {
        border-bottom-left-radius: 12px;
        border-top-left-radius: 12px;
      }

      &:last-of-type {
        border-bottom-right-radius: 12px;
        border-top-right-radius: 12px;
      }
    }
  }
}
