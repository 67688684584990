//base colours
:root {
  --brandBluePrimary: #0071FC;
  --brandBlueSecondary: #213DFC;
  --brandGreen: #00F6A1;
  --green: #00F5A0;
  --red: #D13735;
  --lightred: #EC6767;
  --warning: #FFC107;
  --navy: #4B79B2;
  --white: #ffffff;
  --black: #000000;
  --gray50: #f5f8fa;
  --gray100: #e6e6e6;
  --gray200: #CCCCCC;
  --gray250: #B8B8B8;
  --gray300: #989898;
  --headerGray: #EDF0F2;
  --menuGray: #EFF0F5;
}

//base fonts
body {
  margin: 0;
}

:focus {
  outline: none;
}

html {
  @media (min-width: 992px) {
    font-size: 16px;
  }
  @media (max-width: 991px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 16px;
  }
}

*, :after, :before {
  font-family: 'Roboto', Arial, sans-serif;
  box-sizing: border-box;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 1.5rem;
  }
}

h2 {
  font-size: 2rem; //1.25rem
  font-weight: bold;
}

h3 {
  font-size: 1.5rem; //1rem
  font-weight: bold;
}

h4 {
  font-size: 1.125rem; //0.875rem
  font-weight: medium;
}

p {
  font-size: 1.25rem;
  line-height: 1.3;
  margin-bottom: 1em;

  &.label-big {
    font-size: 1rem;
    font-weight: medium;
  }

  &.label-small {
    font-size: 0.875rem;
  }

  &.label {
    font-size: 0.875rem;
    @media screen and (max-width: 580px) {
      font-size: 0.75rem;
    }
  }


  &.note {
    font-size: 0.6875rem;
  }
}

.input-label {
  font-size: 0.8125rem;
  font-weight: medium;
}

.data-label {
  font-size: 0.75rem;
}

.depo-balance-value {
  font-size: 2.5rem;
  font-weight: medium;
}

.standard-value {
  font-size: 3rem;
  font-weight: medium;
}

.blue-primary {
  color: var(--brandBluePrimary);
}

//form base
.form-control {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: var(--gray50);
  border-radius: 20px;
  padding: 0 15px;
  border: none;
  box-shadow: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--gray200);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--gray300);
  }

  &.error {
    border: 1px solid var(--red);
  }
}

textarea.form-control {
  height: unset;
  resize: none;
}

.list-input-radio {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1rem;

  li.divider {
    border-bottom: 1px solid var(--gray300);
  }

  li:not(:last-child) {
    margin-bottom: 1rem;
  }
}


// buttons
a, .link {
  color: var(--brandBluePrimary);
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.button {
  width: 100%;
  max-width: 312px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  border-radius: 40px;
  transition: 200ms;
  padding: 0 16px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    opacity: 0.6;
  }

  &.full-width {
    max-width: unset;
  }

}

.button:disabled {
  background-color: var(--gray200);
  cursor: not-allowed;

  &:hover {
    opacity: 1;
  }
}

.button-primary {
  background-color: var(--brandBluePrimary);
  color: var(--white);
}

.button-default {
  background-color: var(--white);
  border: 1px solid var(--brandBluePrimary);
  color: var(--brandBluePrimary);
}

.button-cancel {
  background-color: var(--white);
  border: 1px solid var(--lightred);
  color: var(--lightred);
}

.button-red {
  background-color: var(--lightred);
  color: var(--white);
}

.button-loading {
  position: relative;
  left: 30px;
}

.button-loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid var(--gray300);
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.button-loading::after {
  border-top-color: var(--white);;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}


.error-message {
  color: var(--red);
  font-size: 0.7rem;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}


.input-wrapper {
  .label-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      cursor: pointer;
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .input-loading {
    position: absolute;
    right: 10px;
    top: 50%;
    width: 20px;
    height: 20px;
  }

  .input-loading::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid var(--gray300);
    border-radius: 50%;
    animation: input-loading-spinner 1s ease infinite;
  }

  .input-loading::after {
    border-top-color: var(--white);;
  }

  @keyframes input-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }
}


.ant-slider {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  .ant-slider-rail {
    background-color: var(--gray100);
  }

  .ant-slider-track {
    background-color: var(--brandBluePrimary);

    &:hover {
      background-color: var(--brandBluePrimary);
    }
  }

  .ant-slider-handle {
    &::after {
      background-color: var(--brandBluePrimary);
      box-shadow: 0 0 0 2px var(--brandBluePrimary);
    }

    &:focus::after {

    }
  }
}

.slider-value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .label {
    font-size: 0.8125rem;
    font-weight: 500;
  }
}

.react-tooltip.tooltip {
  max-width: 20rem;
  border-radius: 0.75rem;
  background-color: var(--white);
  padding: 0.75rem;
  opacity: 1;
  z-index: 5000;
  box-shadow: 0 0 10px -5px rgba(black, 0.5);

  .tooltip-title {
    font-size: 0.875rem;
    color: var(--brandBluePrimary);
    margin-bottom: 0.25rem;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 500;
  }

  .tooltip-body {
    color: var(--black);
    font-size: 0.75rem;
    font-family: 'Roboto', Arial, sans-serif;
    line-height: 150%
  }

  div.deposit-requirement-tooltip {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    span.title {
      font-size: 18px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.8);
    }

    span.amount {
      font-size: 24px;
      font-weight: 700;
      color: var(--red);
    }

    span.desc {
      font-size: 14px;
      font-weight: 400;
      color: var(--gray250);
    }
    .black {
      color: var(--black);
    }
  }
}

.chevron::before {
  width: 10px;
  border-width: 3px 3px 0 0;
  height: 10px;
  border-style: solid;
  content: '';
  display: inline-block;
  position: relative;
  transform: rotate(-45deg);
  border-color: var(--black);
}

.chevron.right::before {
  transform: rotate(45deg);
}

.chevron.left::before {
  transform: rotate(-135deg);
}

.chevron.up::before {
  transition: all 300ms ease-in-out;
}

.chevron.down::before {
  transform: rotate(135deg);
  transition: all 300ms ease-in-out;
}

.date-picker-range-popup {
  .ant-picker-panel-layout {
    display: block !important;

    > div {
      display: flex;
      flex-direction: row-reverse;
      @media screen and (max-width: 1070px) {
        flex-direction: column;
      }

      .ant-picker-footer {
        border: none;

        .ant-picker-footer-extra {
          padding: 0;
          line-height: unset;

          .ant-picker-presets {
            max-width: none;
            min-width: none;

            ul {
              overflow: scroll;
              height: unset;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 8px;

              li {
                overflow: visible;
                text-overflow: unset;
                font-size: 12px;
              }

              li + li {
                margin-top: 0;
              }
            }

            @media screen and (max-width: 1070px) {
              height: 50px;
              ul {
                flex-direction: row;
                gap: unset;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1070px) {
    .ant-picker-panels > *:last-child button.ant-picker-header-prev-btn {
      visibility: visible !important;
    }

    .ant-picker-panels > *:last-child button.ant-picker-header-super-prev-btn {
      visibility: visible !important;
    }

    .ant-picker-panels > *:first-child {
      display: none;
    }

    .ant-picker-panel-container {
      width: 288px;
    }
  }
}

.date-picker-range-input {
  border-radius: 32px;
  height: 40px;
  padding: 0 42px 0 102px;
  cursor: pointer;

  .ant-picker-range-separator {
    padding: 0 4px;
  }

  .ant-picker-active-bar {
    bottom: 8px;
    height: 2px;
  }

  .ant-picker-input {
    input {
      font-size: 13px;
      font-weight: normal;
      width: 65px;
    }
  }

  .ant-picker-suffix {
    margin-inline-start: 0;
  }
}

.date-picker-range-input.history,
.date-picker-range-input.booking {
  padding-left: 12px;
  padding-right: 12px;
  width: calc(50%);
  background-color: var(--gray50);
  border: 0;
}

.date-picker-range-input.history:first-of-type {
  border-radius: 32px 0 0 32px;
  border-right: 1px solid var(--gray200);
}

.date-picker-range-input.history:last-of-type {
  border-radius: 0 32px 32px 0;
}

.date-picker-range-input.history,
.date-picker-range-input.booking {
  .ant-picker-input > input::placeholder {
    color: var(--gray300);
  }
}


.date-picker-range-input.multiple {
  width: 100%;

  .ant-picker-selector .ant-picker-selection-overflow .ant-picker-selection-overflow-item .ant-picker-selection-item {
    background: var(--brandBluePrimary);
    color: var(--white);
    border-radius: 11px;
    font-size: 12px;
    height: 20px;
    padding-inline-start: 12px;
    padding-inline-end: 6px;
    .ant-picker-selection-item-content {
      line-height: 20px;
      margin-inline-end: 6px;
    }
    .ant-picker-selection-item-remove {
      color: var(--white);
    }
  }
}

.date-picker {
  background-color: var(--gray50);
  padding: 11px 16px;
  height: 40px;
  border-radius: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  font-size: 13px;

  .period {
    color: var(--brandBluePrimary);
    font-weight: bold;
    width: 55px;
    margin-left: 8px;
  }

  .bracket {
    margin-bottom: 1px;

    &.left {
      margin-right: 142px;
    }
  }

  .chevron {
    margin-left: 8px;
  }

  .chevron.down::before {
    top: -3px
  }

  .chevron.up::before {
    top: 0
  }

}

.chart-tooltip {
  background-color: var(--white);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .label {
    font-size: 0.875rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;

    .dot {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 0.5rem;
      display: inline-block;
    }
  }
}

.target-group-button {
  font-size: 11px;
  line-height: 13px;
  font-weight: bold;
  padding: 4px 6px;
  color: var(--white);
  background-color: var(--brandBluePrimary);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  width: max-content;

  &:hover {
    opacity: 0.5;
  }

  img {
    width: 8px;
    height: 8px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.clear {
    background-color: var(--white);
    color: var(--brandBluePrimary);
    border-color: var(--brandBluePrimary);
  }

  &.selected {
    cursor: unset;
    padding: 0;
    max-width: 150px;

    &.disabled {
      cursor: not-allowed;

      &:hover {
        opacity: 0.5;
      }

      .name, .remove {
        cursor: not-allowed;

        &:hover {
          opacity: unset;
        }
      }
    }

    &:hover {
      opacity: unset;
    }

    .name, .remove {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    .name {
      padding: 4px 0 4px 6px;
      text-wrap: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .remove {
      padding: 4px 6px 4px 0;

      img {
        width: 7px;
        height: 7px;
      }
    }
  }

  &.select, &.create {
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    transition: 200ms;
    border-radius: 24px;
    display: flex;
    gap: 8px;
    cursor: pointer;

    img {
      width: 16px;
      height: 16px;
    }

    &:hover {
      opacity: 0.6;
    }

    p.label-small {
      line-height: normal;
    }
  }
}


.notification-popup {
  display: flex;
  position: fixed;
  flex-direction: row;
  top: 20px;
  align-items: center;
  align-self: center;
  max-width: 350px;
  gap: 8px;
  background-color: var(--white);
  padding: 1rem;
  border-radius: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: opacity 0.5s ease;

  @media (max-width: 1365px), (max-height: 705px) {
    top: 100px;
  }

  .message {
    font-size: 16px;
    font-weight: 500;

    &.error {
      color: var(--red);
    }

     &.warning {
      color: var(--red);
    }

    &.success {
      color: var(--brandGreen);
    }
  }
}


div.ant-select.dropdown-select {

  div.ant-select-selector {
    border-radius: 20px;
    background-color: var(--gray50);
    border: 0;
    min-height: 40px;
    padding: 5px 10px;

    span.ant-select-selection-placeholder,
    span.ant-select-selection-item {
      font-size: 13px;
      font-weight: 400;
      margin-left: 8px;
    }

    span.ant-select-selection-placeholder {
      color: var(--gray300);
    }
  }

  span.ant-select-arrow {
    color: var(--black);
  }
}


div.ant-select.ant-select-single {
  height: 40px
}

.searchbar {
  display: flex;
  padding: 12px 16px;
  gap: 8px;
  color: var(--gray200);
  align-items: center;
  background-color: white;
  border: 1px solid var(--gray200);
  border-radius: 24px;
  max-width: 264px;
  width: 100%;

  .searchbar-input {
    border: none;

    &::placeholder {
      color: var(--gray200);
    }
  }
}

.filters-button {
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background-color: white;
  color: var(--gray300);
  border-radius: 24px;
  display: flex;
  gap: 8px;
  width: 100px;
  cursor: pointer;
  border: 1px solid var(--gray200);

  &:hover {
    opacity: 0.6;
  }

  p.label-small {
    line-height: normal;
  }
}